import { FilterStringFilterGroupOperator } from './filter-string-filter-group-operator.enum';
import { FilterStringFilter } from './filter-string-filter.model';

export class FilterStringFilterGroup {

    operator: FilterStringFilterGroupOperator;
    filter: FilterStringFilter;

    constructor(item?: Partial<FilterStringFilterGroup>) {
        Object.assign(this, item);

        this.operator = this.operator || FilterStringFilterGroupOperator.None;
        this.filter = new FilterStringFilter(this.filter);
    }

  }
