import { WebAgGridViewMemberFilterType } from 'app/center-v2/core/view-system/models/web-view-member-filter-type.enum';
import { WebViewMemberSort } from 'app/center-v2/core/view-system/models/web-view-member-sort.enum';
import { WebViewMemberValueType } from 'app/center-v2/core/view-system/models/web-view-member-value-type.enum';
import { WebCenterType, WebCenterTypeMember, WebCenterTypeMemberSpecialValueType, WebCenterTypeRelation, WebObject, WebRelationPointer, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { WorkspaceSolutionTypeMember } from 'app/center-v2/shared/models/workspace-solution-type/workspace-solution-type-member.model';
import { SelectItem } from 'primeng/api';
import { GuidUtils } from '.';
import { GridCellRendererText } from '../components/grid';
import { GridCellEditorFormField } from '../components/grid/grid-celleditor-form-field/grid-celleditor-form-field.component';
import { GridTooltipBarcode } from '../components/grid/grid-tooltip-barcode/grid-tooltip-barcode.component';
import { ObjectOrValue, SolutionTypeSubMemberType } from '../models';
import { CaseUtils } from './case.utils';
import { JsonUtils } from './json.utils';
import { GridTooltipQRcode } from '../components/grid/grid-tooltip-qrcode/grid-tooltip-qrcode.component';
import { Observable } from 'rxjs';

export class GridUtils {

  static buildColumnFromWorkspaceSolutionTypeMember(
    solutionTypes: WorkspaceSolutionType[],
    solutionType: WorkspaceSolutionType,
    headerName: string,
    stMember: WorkspaceSolutionTypeMember,
    webCenterTypes: WebCenterType[],
    parentComponent: any,
    userLanguageGuidId: string,
    useRelationPointersAsRows?: boolean,
    cellRendererParamsExtras?: any,
    cellStyle?: (params: any) => any,
    valueSetter?: (params: any) => void,
    checkboxSelection?: boolean,
    floatingFilter?: boolean,
    fieldOrValueGetter?: (params: any) => any,
    cellEditorParamsExtras?: any,
  ): any {
    let fieldPath;
    if (GuidUtils.isValid(stMember.guidId)) {
      fieldPath = (useRelationPointersAsRows ? 'web2Object.' : '') + stMember.getWeb2ObjectPath();
    } else {
      fieldPath = CaseUtils.camelize(stMember.longName);
    }

    const webCenterType = (webCenterTypes || []).find((ct: WebCenterType) => {
      return ct.typeGuidId === stMember.subMembers[stMember.subLevels].fromTypeGuidId;
    });
    const webCenterTypeMember = (webCenterType?.members || []).find((ctm: WebCenterTypeMember) => {
      return ctm.guidId === stMember.centerTypeMemberGuidId;
    });
    const webCenterTypeRelation = (webCenterType?.relations || []).find((ctm: WebCenterTypeRelation) => {
      return ctm.typeTreeRelationGuidId === stMember.centerTypeMemberGuidId;
    });
    // if (webCenterTypeMember) console.log(webCenterTypeMember.name, webCenterTypeMember.web2Access)

    if (!fieldOrValueGetter) {
      if (
        stMember.objectOrValue === ObjectOrValue.Object &&
        stMember.subMembers[stMember.subLevels].subMemberType === SolutionTypeSubMemberType.Relation
      ) {
        fieldOrValueGetter = (params: any) => {
          let relationWebObject = JsonUtils.deepFind(params.data, (useRelationPointersAsRows ? 'web2Object.' : '') + stMember.getWeb2ObjectPath());
          if (!relationWebObject && stMember.fallbackRelationTypeName) { // fallback
            relationWebObject = JsonUtils.deepFind(params.data, (useRelationPointersAsRows ? 'web2Object.' : '') + `relations.${CaseUtils.camelize(stMember.fallbackRelationTypeName)}.web2Object`);
          }

          let relationMemberSolutionType = (solutionTypes || []).find((st: WorkspaceSolutionType) => {
            return st.baseSolutionTypeGuidId === relationWebObject?.typeGuidId;
          });
          // this was needed mainly for the WebLocation WebWarehouseLocation mixups, since we can't rely on the relationTypeGuidId but need to use the actual returned object typeGuidId
          relationMemberSolutionType = relationMemberSolutionType || (solutionTypes || []).find((st: WorkspaceSolutionType) => {
            return st.baseSolutionTypeGuidId === stMember.subMembers[stMember.subLevels].toTypeGuidId;
          });

          if (!relationWebObject) {
            // check if the relation is a collectionRelation
            const relationPointers = JsonUtils.deepFind(params.data, stMember.getWeb2ObjectPath().replace(/.web2Object/g, ''));
            if (Array.isArray(relationPointers) && relationPointers?.length) {
              return (relationPointers || []).map((wrp: WebRelationPointer) => {
                return relationMemberSolutionType?.getMainMemberLabel(wrp.web2Object) || wrp.web2Object?.members?.name || wrp.web2Object?.guidId
              });
            } else {
              return '';
            }
          }

          return relationMemberSolutionType?.getMainMemberLabel(relationWebObject) || relationWebObject?.members?.name || relationWebObject?.guidId;
        };
      } else {
        fieldOrValueGetter = (params: any) => {
          const indexOfSubTypes = fieldPath.indexOf('subTypes.');
          if (indexOfSubTypes >= 0) {
            let rootSubType = fieldPath.substring(indexOfSubTypes + 'subTypes.'.length);
            rootSubType = rootSubType.substring(0, rootSubType.indexOf('.'));
            const obj = JsonUtils.deepFind(params.data, fieldPath.substring(0, indexOfSubTypes));
            if (obj?.web2Type === rootSubType) {
              fieldPath = fieldPath.replace('subTypes.' + rootSubType + '.', '');
            }
          }

          const membersPathSubString = fieldPath.substring((fieldPath || '').indexOf('members.'));
          const key = membersPathSubString.substring('members.'.length);
          const newFieldPath = fieldPath.replace(membersPathSubString, `languageMembers.${key}.${userLanguageGuidId}`);
          if (!params?.data) return '';

          const newFieldPathValue = JsonUtils.deepFind(params.data, newFieldPath);
          if (userLanguageGuidId && newFieldPathValue) return newFieldPathValue;

          const value = JsonUtils.deepFind(params.data, fieldPath);

          if (!stMember.fieldOptions?.length && !webCenterTypeMember?.$fieldOptions?.length) return value;

          const fieldOptions = stMember.fieldOptions?.length ? stMember.fieldOptions
          : webCenterTypeMember?.$fieldOptions?.length ? webCenterTypeMember?.$fieldOptions
          : [];
          if (webCenterTypeMember?.specialValuesType === WebCenterTypeMemberSpecialValueType.IsFlags) {
            const multiSelectFlagsValue = [];
            for (const option of fieldOptions || []) {
              if ((value & option.value) === option.value) {
                multiSelectFlagsValue.push(option.label);
              }
            }
            return multiSelectFlagsValue.join(', ');
          } else {
            const enumValue = (fieldOptions || [])
            .find((item: SelectItem) => {
              return item.value == value;
            })?.label;

            return enumValue || value;
          }
        }
      }
    }

    const cellRendererParams = Object.assign({
      customControlType: stMember.customControlType,
      showCopyIcon: stMember.showCopyIcon,
      translate: webCenterTypeMember?.$fieldOptions?.length, // TODO: move this upstream...this will translate "dropdown" values but not multi-select strings with more than one value
    }, cellRendererParamsExtras || {});

    return GridUtils.buildColumn(
      headerName + (webCenterTypeMember?.uoM ? ' (' + webCenterTypeMember.uoM.replace('CUR', '€')?.replace('PER', '%') + ')' : ''),
      fieldOrValueGetter,
      stMember.customControlType || stMember.fieldType,
      parentComponent,
      (webCenterTypeMember || webCenterTypeRelation)?.web2Access > 1 && !solutionType?.style?.grid?.readOnly && !stMember.readOnly,
      stMember.sort,
      useRelationPointersAsRows,
      cellRendererParams,
      solutionType?.style?.grid?.floatingFilters || floatingFilter,
      undefined,
      fieldPath,
      valueSetter,
      cellStyle,
      ['Barcode', 'QRcode'].indexOf(stMember.customControlType) >= 0 && typeof fieldOrValueGetter === 'string' ? fieldOrValueGetter : undefined,
      ['Barcode', 'QRcode'].indexOf(stMember.customControlType) >= 0 && typeof fieldOrValueGetter !== 'string' ? fieldOrValueGetter : undefined,
      stMember.customControlType === 'Barcode' ? GridTooltipBarcode : stMember.customControlType === 'QRcode' ? GridTooltipQRcode : undefined,
      webCenterTypeMember?.$fieldOptions,
      checkboxSelection,
      stMember.objectOrValue === ObjectOrValue.Object && stMember.subMembers[stMember.subLevels].subMemberType === SolutionTypeSubMemberType.Relation ? 'agSetColumnFilter' : undefined,
      cellEditorParamsExtras
    );
  }

  static buildColumnFromRelationWebObject(
    headerName: string,
    solutionTypes: WorkspaceSolutionType[],
    getRelationWebObject: (params: any) => WebObject,
    parentComponent: any,
    relationField?: string,
    sort?: 'asc' | 'desc' | 'not-sortable',
    cellRendererParamsExtras?: any,
  ): any {
    let fieldOrValueGetter = (params: any) => {
      const relationWebObject = getRelationWebObject(params);
      if (relationField) {
        return relationWebObject?.members ? relationWebObject?.members[relationField] : undefined;
      } else {
        const relationMemberSolutionType = (solutionTypes || []).find((st: WorkspaceSolutionType) => {
          return st.baseSolutionTypeGuidId === relationWebObject?.typeGuidId;
        });
        return relationMemberSolutionType?.getMainMemberLabel(relationWebObject) || relationWebObject?.members?.name || relationWebObject?.guidId;
      }
    };

    return GridUtils.buildColumn(
      headerName,
      fieldOrValueGetter,
      'text',
      parentComponent,
      false,
      sort,
      false,
      cellRendererParamsExtras,
    );
  }

  static buildColumnFromViewMemberWebObject(
    viewMemberWO: WebObject,
    viewDesignFilterMemberValues: any,
    cellRendererParamsExtras: any,
    cellStyle: (params: any) => any,
    parentComponent: any,
  ): any {
    const fieldType: string = viewMemberWO.members.valueType === WebViewMemberValueType.Date ? 'date' :
    viewMemberWO.members.valueType === WebViewMemberValueType.DateTime ? 'datetime' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'number' :
      'text';
    const chartDataType = (viewMemberWO.members.valueType === WebViewMemberValueType.Date || viewMemberWO.members.valueType === WebViewMemberValueType.DateTime) ? 'time' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'series' :
      viewMemberWO.members.valueType === WebViewMemberValueType.Unknown ? 'excluded' :
      'category';
    const filter = (viewMemberWO.getSubType('WebAgGridViewDesignMember')?.members?.filterType === WebAgGridViewMemberFilterType.List && viewDesignFilterMemberValues[viewMemberWO.guidId]) ? 'agSetColumnFilter' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Date || viewMemberWO.members.valueType === WebViewMemberValueType.DateTime) ? 'agDateColumnFilter' :
      (viewMemberWO.members.valueType === WebViewMemberValueType.Number || viewMemberWO.members.valueType === WebViewMemberValueType.Percentage) ? 'agNumberColumnFilter' :
      'agTextColumnFilter';
    const filterValuesMap = !viewDesignFilterMemberValues[viewMemberWO.guidId] ? {} :
      viewDesignFilterMemberValues[viewMemberWO.guidId]
      .reduce((previousValue, currentValue) => {
        previousValue[currentValue.objectGuidId] = currentValue.value;
        return previousValue;
      }, {});

    const filterParams = ['agDateColumnFilter', 'agSetColumnFilter'].indexOf(filter) >= 0 ?
      {
        comparator: (filterLocalDate, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return 0;

          var cellDate = new Date(dateAsString);

          return cellDate < filterLocalDate ? -1 : cellDate > filterLocalDate ? 1 : 0;
        },
        values: filter === 'agSetColumnFilter' ?
          viewDesignFilterMemberValues[viewMemberWO.guidId].map((item: any) => {
            return item.objectGuidId || item.value;
          }) :
          undefined,
        valueFormatter: (params: any) => {
          return filterValuesMap[params.value] || params.value;
        },
      } : undefined;

    const cellRendererParams = Object.assign({
      filter: () => { return parentComponent.gridQuickFilter; },
      isDate: fieldType === 'date' || fieldType === 'datetime',
      isTime: fieldType === 'time',
      withTime: fieldType === 'datetime',
    }, cellRendererParamsExtras || {});

    return {
      headerName: viewMemberWO.members?.name + (viewMemberWO.members.valueType === WebViewMemberValueType.Percentage ? ' (%)' : ''),
      field: 'values.' + viewMemberWO.guidId,
      colId: viewMemberWO.guidId,
      cellRenderer: GridCellRendererText,
      cellRendererParams: cellRendererParams,
      cellStyle: cellStyle,
      chartDataType: chartDataType,
      editable: false,
      filter: filter,
      filterParams: filterParams,
      floatingFilter: true,
      hide: viewMemberWO.members?.hide || viewMemberWO.members.preFilter,
      rowGroup: viewMemberWO.members?.rowGroup,
      sort: viewMemberWO.members?.sort === WebViewMemberSort.Asc ? 'asc' : viewMemberWO.members?.sort === WebViewMemberSort.Desc ? 'desc' : undefined,
      sortable: true,
      width: 120,
    };
  }

  static buildColumn(
    headerName: string,
    fieldOrValueGetter: string | any,
    fieldType: string | ((params: any) => string),
    parentComponent: any,
    editable: boolean | ((params: any) => boolean) = false,
    sort?: 'asc' | 'desc' | 'not-sortable',
    useRelationPointersAsRows?: boolean,
    cellRendererParamsExtras?: any,
    floatingFilter?: boolean,
    chartDataType?: 'category' | 'series' | 'time' | 'excluded' | undefined,
    colId?: string,
    valueSetter?: any,
    cellStyle?: (params: any) => any,
    tooltipField?: string,
    tooltipValueGetter?: any,
    tooltipComponent?: any,
    fieldOptions?: SelectItem[] | ((params: any) => SelectItem[]) | ((params: any) => Observable<SelectItem[]>),
    checkboxSelection?: boolean,
    filter?: string,
    cellEditorParamsExtras?: any,
  ): any {
    const cellRendererParams = Object.assign({
      filter: () => { return parentComponent.gridQuickFilter; },
      isDate: fieldType === 'date' || fieldType === 'datetime',
      isTime: fieldType === 'time',
      options: fieldOptions,
      withTime: fieldType === 'datetime',
      tooltip: (params: any) => {
        if (typeof params.value === 'string' && params.value.length > 60) return params.value;
        return undefined;
      },
      type: fieldType,
    }, cellRendererParamsExtras || {});

    const cellEditorParams = Object.assign({
      // cellValueChanged: (params: any) => {  },
      options: fieldOptions,
      type: fieldType === 'GreenRedCircle' ? 'checkbox' : fieldType,
    }, cellEditorParamsExtras || {});

    return {
      headerName: headerName,
      field: typeof fieldOrValueGetter === 'string' ? (useRelationPointersAsRows ? 'web2Object.' : '') + fieldOrValueGetter : undefined,
      colId: colId || (typeof fieldOrValueGetter === 'string' ? fieldOrValueGetter : undefined),
      valueGetter: typeof fieldOrValueGetter === 'function' ? fieldOrValueGetter : undefined,
      valueSetter: valueSetter,
      cellEditor: GridCellEditorFormField,
      cellEditorParams: cellEditorParams,
      cellRenderer: GridCellRendererText,
      cellRendererParams: cellRendererParams,
      cellStyle: cellStyle || GridUtils.getDefaultCellStyleFunction([]),
      chartDataType: chartDataType,
      checkboxSelection: checkboxSelection,
      editable: (params: any) => {
        if (params.node?.rowPinned) return false;
        return editable && typeof editable === 'function' ? editable(params) : editable;
      },
      filter: filter || (
        fieldType === 'date' || fieldType === 'datetime' ? 'agDateColumnFilter'
        : fieldType === 'number' ? 'agNumberColumnFilter'
        : 'agTextColumnFilter'
      ),
      filterParams: fieldType === 'date' || fieldType === 'datetime' ? {
        comparator: (filterLocalDate, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) return 0;

          var cellDate = new Date(dateAsString);

          return cellDate < filterLocalDate ? -1 : cellDate > filterLocalDate ? 1 : 0;
        }
      } : undefined,
      floatingFilter: floatingFilter,
      headerCheckboxSelection: checkboxSelection,
      headerCheckboxSelectionFilteredOnly: checkboxSelection,
      sort: sort !== 'not-sortable' ? sort : undefined,
      sortable: sort !== 'not-sortable' ? true : false,
      tooltipField: tooltipField,
      tooltipValueGetter: tooltipValueGetter,
      tooltipComponent: tooltipComponent,
      width: 120,
    };
  }

  static getDefaultCellStyleFunction(solutionTypes: WorkspaceSolutionType[]) {
    return (params: any) => {
      if (!params.colDef?.cellRendererParams?.isLink) return {};

      const solutionType = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === params.data?.typeGuidId);
      const path = (solutionType?.style?.grid?.rowColorMember as WorkspaceSolutionTypeMember)?.getWeb2ObjectPath();
      const value = JsonUtils.deepFind(params?.data, path);

      const colorMapping = (solutionType?.style?.grid?.rowColorMappings || []).find((item: any) => {
        return item.value === (value || '').toString();
      });
      return { color: colorMapping?.linkColor || '#0068FF' };
    };
  }

  static getParamValue(params: any, paramField: any): any {
    return paramField && typeof paramField === 'function' ? paramField(params) : paramField;
  }

  static getParamValueSuccessCallback(params: any, paramField: any, successCallback: (data: any | any[]) => void): void {
    return paramField && typeof paramField === 'function' ? paramField(params, successCallback) : paramField;
  }

  static filterParamsLocalDateAtMidnightComparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;
    if (dateAsString == null) return 0;

    let cellDate = new Date(dateAsString);
    cellDate = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

    return cellDate < filterLocalDateAtMidnight ? -1 : cellDate > filterLocalDateAtMidnight ? 1 : 0;
  }

}