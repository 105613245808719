import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WebObject, WebPointerStatus, WebRelationPointer, WebResponse, WorkspaceSolutionType, WorkspaceSolutionTypeMember } from 'app/center-v2/shared/models';
import { GenericService } from 'app/center-v2/shared/services';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { Dropdown } from 'primeng/dropdown';
import { SelectItem } from 'primeng/api';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BrowserUtils } from 'app/shared/utils';


@Component({
  selector: 'lc-form-field-relation',
  templateUrl: 'form-field-relation.component.html',
  styleUrls: ['./form-field-relation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldRelationComponent),
    multi: true
  }],
})
export class FormFieldRelationComponent implements ControlValueAccessor, AfterViewInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild(Dropdown, { static: false }) dropdown: Dropdown;

  @Input() allowCopyOnHover: boolean;
  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() max: number;
  @Input() placeholder: string;
  @Input() relationControlType?: 'edit-in-dropdown' | 'edit-in-popup' | 'inline-form';
  @Input() relationLinkActive?: boolean;
  @Input() relationName?: string;
  @Input() relationOptions?: SelectItem[] | any[];
  @Input() relationParentWebObject?: WebObject;
  @Input() relationSolutionType?: WorkspaceSolutionType;
  @Input() relationWebObject?: WebObject;
  @Input() solutionTypeMember?: WorkspaceSolutionTypeMember;
  @Input() required: boolean;
  @Input() separator: string;

  @Output() labelIconRightClick = new EventEmitter<MouseEvent>();
  @Output() relationLinkClick = new EventEmitter<MouseEvent>();
  @Output() relationEditClick = new EventEmitter<MouseEvent>();

  onChange: (x: any) => {};
  onTouched: () => {};

  copyToClipboardMousePosition = { x: 0, y: 0 };
  dropdownMode: boolean;
  dropdownModeCanAdd: boolean;
  invalid: boolean;
  isFocused = false;
  isTouched: boolean;
  value: string;
  values: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {

  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      this.cdr.markForCheck();

      setTimeout(() => {
        this.cdr.markForCheck();
      }, 100);
    }
  }

   // From ControlValueAccessor interface
   registerOnChange(fn: any) {
    this.onChange = (value: any) => {
      this.invalid = this.required && (value == null || value === '' || (!(value instanceof WebObject) && !value?.length));
      return fn(value);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = () => {
      this.isTouched = true;
      return fn();
    };
  }

  ngAfterViewInit() {
    if (this.relationControlType !== 'edit-in-dropdown') return;

    const relationAddBehavior = this.solutionTypeMember?.relationAddBehavior || this.relationSolutionType?.style?.new?.addRelationBehavior || [];
    this.dropdownModeCanAdd = relationAddBehavior.indexOf('add') >= 0;
  }

  onRelationEditClick() {
    if (this.relationControlType === 'inline-form') return;
    if (this.relationControlType === 'edit-in-popup' || !this.relationControlType) { // default behavior for form V2
      this.relationEditClick.emit();
      return;
    };

    // if (this.relationControlType === 'edit-in-dropdown')
    if (!this.relationSolutionType) {
      this.notificationService.warn(
        this.translateService.instant('Missing'),
        `Relation SolutionType ${this.translateService.instant('is missing')}.`,
      );
      return;
    }

    this.dropdownMode = true;
    this.cdr.markForCheck();

    if (this.relationOptions) return;

    this.genericService.list(this.relationSolutionType.baseSolutionTypeGuidId)
    .subscribe((response: WebResponse) => {
      this.relationOptions = (response.getWebObjects() || [])
      .map((wo: WebObject) => {
        if (wo.guidId === this.relationWebObject?.guidId) this.relationWebObject = wo;

        return { label: this.relationSolutionType.getMainMemberLabel(wo), value: wo }
      });
      this.relationOptions.splice(0, 0, { label: this.translateService.instant('(none)'), value: null });
      this.cdr.markForCheck();

      setTimeout(() => {
        this.dropdown.show();
        this.cdr.markForCheck();
      }, 10);
    });
  }

  onDropdownChange() {
    if (!this.relationParentWebObject) return;

    let request = null;

    const existingRelationPointer = this.relationParentWebObject.getRelationPointer(this.relationName) as WebRelationPointer;
    if (!this.relationWebObject && existingRelationPointer) {
      existingRelationPointer.web2Status = WebPointerStatus.Remove;

      request = of(existingRelationPointer);
    } else if (existingRelationPointer?.web2Object) {
      this.relationParentWebObject.web2Status =  WebPointerStatus.Update;
      existingRelationPointer.web2Status = WebPointerStatus.Update;
      existingRelationPointer.web2Object = this.relationWebObject;
      request = of(existingRelationPointer);
    } else {
      request = this.genericService.newDraftRelation(
        this.relationParentWebObject.typeGuidId,
        this.relationName,
        this.relationWebObject,
      );
    }

    request.pipe(
      mergeMap((wrp: WebRelationPointer) => {
        this.relationParentWebObject.addRelationPointer(this.relationName, wrp, false);

        if (this.relationParentWebObject.web2Status === WebPointerStatus.New) return of(null);
        else return this.genericService.update([this.relationParentWebObject]);
      })
    ).subscribe((response: WebResponse) => {
      this.value = this.relationSolutionType.getMainMemberLabel(this.relationWebObject);
      this.onChange(this.relationWebObject);
      this.dropdownMode = false;

      this.notificationService.success(
        this.translateService.instant('Success'),
        this.translateService.instant('Item updated successfully.'),
      );
      this.cdr.markForCheck();
    });
  }

  copyToClipboardMouseDown(ev: MouseEvent) {
    if (!this.allowCopyOnHover) return;

    this.copyToClipboardMousePosition.x = ev.screenX;
    this.copyToClipboardMousePosition.y = ev.screenY;
  }

  copyToClipboardClick(ev: MouseEvent) {
    if (!this.allowCopyOnHover) return;
    if (this.copyToClipboardMousePosition.x !== ev.screenX || this.copyToClipboardMousePosition.y !== ev.screenY) return;

    ev.stopPropagation();

    BrowserUtils.copyToClipboard(this.value || '');

    this.notificationService.info(
      this.translateService.instant('Info'),
      this.translateService.instant('Content copied to the clipboard.'),
    );
  }

}
