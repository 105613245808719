import { ObjectOrValue, SolutionTypeMember, SolutionTypeSubMemberType } from 'app/shared/models';
import { CaseUtils } from 'app/shared/utils';
import { FilterStringFilter } from '../filter-string';


export class WorkspaceSolutionTypeMember extends SolutionTypeMember {

  /** @deprecated */ addSubTypeIfMissing: boolean;
  customControlType: string;
  defaultValue?: string;
  explanation: string;
  fallbackRelationTypeName: string;
  fieldOptionsRelationMember: WorkspaceSolutionTypeMember;
  groupName?: string;
  hiddenFilterStringFilter?: FilterStringFilter;
  isDefaultTab?: boolean;
  isElevated?: boolean;
  linkMember?: string;
  linkBehavior?: 'always' | 'if-empty';
  missingSubTypeBehavior: 'allow-add' | 'auto-add';
  readOnlyEx: 'false' | 'true' | 'if-not-empty';

  relationAddBehavior?: ('add' | 'select')[];
  relationDeleteBehavior?: 'not-allowed' | 'relation-only' | 'relation-and-object';
  relationSetMemberOnNew?: string;
  relationSetReverseRelationOnAddNew?: string;
  relationFilterGroupCenterTypeGuidId?: string;
  relationFilterGroupTypeRelationName?: string;

  required?: boolean;
  showRelationCount?: boolean;
  siteRestricted?: boolean;
  sort?: 'asc' | 'desc' | 'not-sortable';

  // barcode?: boolean;
  showCopyIcon?: boolean;

  constructor(item?: Partial<WorkspaceSolutionTypeMember>) {
    super(item);

    // deprecations...
    if (this.addSubTypeIfMissing != null && !this.missingSubTypeBehavior) {
      this.missingSubTypeBehavior = this.addSubTypeIfMissing ? 'auto-add' : undefined;
      this.addSubTypeIfMissing = undefined;
    }

    this.isElevated = !!this.isElevated;
    this.readOnly = this.readOnly != null ? this.readOnly : this.systemReadOnly;
    this.readOnlyEx = this.readOnlyEx != null ? this.readOnlyEx : (this.readOnly ? 'true' : 'false');
    if (
      this.objectOrValue === ObjectOrValue.Object &&
      this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Relation
    ) {
      this.relationDeleteBehavior = this.relationDeleteBehavior || 'not-allowed';
    }

    this.fieldOptionsRelationMember = this.fieldOptionsRelationMember ? new WorkspaceSolutionTypeMember(this.fieldOptionsRelationMember) : undefined;
  }

  getWeb2ObjectPath() {
    const nameSplit = (this.longName || '').split(' ');
    const pathSplit = (this.path || '').split('.');
    let result = '';
    if (pathSplit?.length && !!pathSplit[0]) {
      for (let i = 0; i < pathSplit.length; i++) {
        if (this.subMembers[i].subMemberType === SolutionTypeSubMemberType.Relation) {
          result += `relations.${CaseUtils.camelize(pathSplit[i])}.web2Object.`;
        } else {
          result += `subTypes.${pathSplit[i].replace('Logic', 'Web')}.`;
        }
      }

      if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Relation) {
        result += `relations.${CaseUtils.camelize(nameSplit[nameSplit.length - 1])}`;
        if (!this.subMembers[this.subLevels].relationCollection) result += '.web2Object';
      } else if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Subcast) {
        result += `subTypes.${nameSplit[nameSplit.length - 1].replace('Logic', 'Web')}`;
      } else {
        result += 'members.' + CaseUtils.camelize(nameSplit[nameSplit.length - 1]);
      }
    } else if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Relation) {
      result += `relations.${CaseUtils.camelize(this.longName)}`;
      if (!this.subMembers[this.subLevels].relationCollection) result += '.web2Object';
    } else if (this.subMembers[this.subLevels] && this.subMembers[this.subLevels].subMemberType === SolutionTypeSubMemberType.Subcast) {
      result += `subTypes.${this.longName.replace('Logic', 'Web')}`;
    } else {
      result += 'members.' + CaseUtils.camelize(this.longName);
    }
    return result;
  }

}
