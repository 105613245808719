import { DictString } from 'app/shared/models';
import { WebLazyRelation } from './web-lazy-relation.model';
import { WebPointerStatus } from './web-pointer-status.enum';
import { WebRelationPointer } from './web-relation-pointer.model';


export interface IWebObject {

  guidId: string;
  typeGuidId: string;
  siteGuidId: string;
  withBase: boolean;
  sysId: string;
  defaultLanguageValuesLanguageGuidId: string;
  parentGuidId: string;
  environmentGuidId: string;
  cid: string;
  collectionObject: boolean;
  createdDateTime: string;
  changedDateTime: string;
  web2Type: string;
  web2Status: WebPointerStatus;
  web2LoadTemplate: 'Base' | 'List' | 'Complete';

  members: any;
  baseMembers: any;
  languageMembers: any;
  lazyRelations: DictString<WebLazyRelation>;
  relations: DictString<WebRelationPointer | WebRelationPointer[]>;
  subTypes: DictString<WebObject>;

  unsupportedWeb2Type: string;

  $originalWebObject: IWebObject;
}
export class WebObject implements IWebObject {

  guidId: string;
  typeGuidId: string;
  siteGuidId: string;
  withBase: boolean;
  sysId: string;
  defaultLanguageValuesLanguageGuidId: string;
  parentGuidId: string;
  environmentGuidId: string;
  cid: string;
  collectionObject: boolean;
  createdDateTime: string;
  changedDateTime: string;
  web2Type: string;
  web2Status: WebPointerStatus;
  web2LoadTemplate: 'Base' | 'List' | 'Complete';

  members: any;
  baseMembers: any;
  languageMembers: any;
  lazyRelations: DictString<WebLazyRelation>;
  relations: DictString<WebRelationPointer | WebRelationPointer[]>;
  baseRelations: DictString<WebRelationPointer | WebRelationPointer[]>;
  subTypes: DictString<WebObject>;
  notincludedrelations: DictString<any>;

  unsupportedWeb2Type: string;

  $originalWebObject: IWebObject;

  constructor(item?: Partial<WebObject>) {
    Object.assign(this, item);

    if (this.withBase) {
      for (const key of Object.keys(this.members)) {
        if (this.members[key] == null) delete this.members[key];
      }
      this.members = Object.assign({}, this.baseMembers || {}, this.members || {});

      for (const key of Object.keys(this.relations || {})) {
        if (this.relations[key] == null || !(this.relations[key] as WebRelationPointer[]).length) delete this.relations[key];
      }
      this.relations = Object.assign({}, this.baseRelations || {}, this.relations || {});
    }
    else {
      this.members = Object.assign({}, this.members || {});
      this.relations = Object.assign({}, this.relations || {});
    }

    this.web2Status = this.web2Status || WebPointerStatus.Active;

    for (const key of Object.keys(this.relations)) {
      if (Array.isArray(this.relations[key])) {
        this.relations[key] = (this.relations[key] as WebRelationPointer[])
        .map((rp: WebRelationPointer) => {
          return rp?.childGuidId ? new WebRelationPointer(rp) : undefined;
        });
      } else {
        this.relations[key] = (this.relations[key] as WebRelationPointer)?.members ? new WebRelationPointer(this.relations[key] as WebRelationPointer) : undefined;
      }
    }

    for (const key of Object.keys(this.subTypes || {})) {
      this.subTypes[key] = !this.subTypes[key]?.unsupportedWeb2Type ? new WebObject(this.subTypes[key]) : this.subTypes[key];
    }

    this.updateOriginalWebObject();
  }

  static cleanWebObjectsForUpdate(webObjects: WebObject[], siteGuidId?: string, skipSetStatusToUpdate?: boolean) {
    const webObjectsToUpdate = [];
    for (const wo of JSON.parse(JSON.stringify(webObjects))) {
      webObjectsToUpdate.push(wo);

      // We don't need to send back the LazyRelations property
      delete wo.lazyRelations;
      delete wo.notincludedrelations;

      if (wo.web2Status === WebPointerStatus.New) {
        wo.siteGuidId = siteGuidId || wo.siteGuidId;
      } else if (wo.web2Status !== WebPointerStatus.New) {
        // Clean LanguageMembers
        const updatedLanguageMembers = [];
        for (const key of Object.keys(wo.languageMembers || {})) {
          for (const languageGuidId of Object.keys(wo.languageMembers[key] || {})) {
            if (wo.languageMembers[key][languageGuidId] === wo.$originalWebObject.languageMembers[key][languageGuidId]) {
              delete wo.languageMembers[key][languageGuidId];
            } else {
              updatedLanguageMembers.push(key);
            }
          }
          if (!Object.keys(wo.languageMembers[key] || {}).length) {
            delete wo.languageMembers[key];
          }
        }

        // Clean Members
        for (const key of Object.keys(wo.members || {})) {
          if (
            key.indexOf('$') === 0
            || (
              updatedLanguageMembers.indexOf(key) < 0
              && wo.members[key] === wo.$originalWebObject.members[key]
            )
          ) {
            delete wo.members[key];
          }
        }
      }

      // Clean Relations
      for (const key of Object.keys(wo.relations || {})) {
        const originalRelationPointers = wo.$originalWebObject.relations[key] || [];
        if (wo.relations[key] && JSON.stringify(wo.relations[key]).localeCompare(JSON.stringify(originalRelationPointers)) !== 0) {
          if (Array.isArray(wo.relations[key])) {
            let originalIndex = 0;
            let removedRelationCount = 0;
            for (let i = 0; i < (wo.relations[key] as WebRelationPointer[]).length; i++) {
              originalIndex = i + removedRelationCount;
              const rp: WebRelationPointer = wo.relations[key][i];
              if (
                originalIndex >= originalRelationPointers?.length ||
                JSON.stringify(rp).localeCompare(JSON.stringify(originalRelationPointers ? originalRelationPointers[originalIndex] : null)) !== 0
              ) {
                for (const key2 of Object.keys(rp.members || {})) {
                  if (rp.members[key2] === originalRelationPointers[originalIndex]?.members[key2]) {
                    delete rp.members[key];
                  } else if (rp.web2Status === WebPointerStatus.Active) {
                    rp.web2Status = WebPointerStatus.Update;
                  }
                }
                if (rp.web2Object) {
                  rp.web2Object = this.cleanWebObjectsForUpdate([rp.web2Object])[0];
                }
              } else {
                (wo.relations[key] as WebRelationPointer[]).splice(i, 1);
                removedRelationCount++;
                i--;
              }
            }
          } else {
            const rp = wo.relations[key] as WebRelationPointer;
            for (const key2 of Object.keys(rp.members || {})) {
              if (rp.members[key2] === (wo.$originalWebObject.relations[key] as WebRelationPointer).members[key2]) {
                delete rp.members[key];
              } else if (rp.web2Status === WebPointerStatus.Active) {
                rp.web2Status = WebPointerStatus.Update;
              }
            }

            if (rp.web2Object) {
              // the commented section may be need for NEW complex objects with several children, but for the time being i got it working another way...
              rp.web2Object = this.cleanWebObjectsForUpdate([rp.web2Object]/*, null, true*/)[0];
            }
          }
        } else {
          delete wo.relations[key];
        }
      }

      // Clean SubTypes
      for (const key of Object.keys(wo.subTypes || {})) {
        if (
          wo.subTypes[key] &&
          (wo.subTypes[key].web2Status !== WebPointerStatus.Active ||
          JSON.stringify(wo.subTypes[key]) !== JSON.stringify(wo.$originalWebObject.subTypes[key]))
        ) {
          wo.subTypes[key] = this.cleanWebObjectsForUpdate([wo.subTypes[key]])[0];
        } else {
          delete wo.subTypes[key];
        }
      }

      // Set Web2Status flag accordingly
      if (
        !skipSetStatusToUpdate &&
        wo.web2Status === WebPointerStatus.Active &&
        (
          Object.keys(wo.languageMembers || {}).length ||
          Object.keys(wo.members || {}).length ||
          Object.keys(wo.relations || {}).length ||
          Object.keys(wo.subTypes || {}).length
        )
      ) {
        wo.web2Status = WebPointerStatus.Update;
      }
    }

    return webObjectsToUpdate;
  }

  addRelationPointer(relationName: string, relationPointer: WebRelationPointer, collectionRelation: boolean): void {
    if (collectionRelation) {
      this.relations[relationName] = this.relations[relationName] || [];
      const existingRP = (this.relations[relationName] as WebRelationPointer[]).find(item => item.relationGuidId === relationPointer.relationGuidId);
      if (!existingRP) {
        (this.relations[relationName] as WebRelationPointer[]).push(relationPointer);
      }
    } else {
      this.relations[relationName] = relationPointer;
    }
  }

  addRelationPointers(relationName: string, relationPointers: WebRelationPointer[]): void {
    this.relations[relationName] = this.relations[relationName] || [];
    for (const rp of relationPointers || []) {
      const existingRP = (this.relations[relationName] as WebRelationPointer[]).find(item => item.childGuidId === rp.childGuidId);
      if (existingRP) {
        if (existingRP.members) Object.assign(existingRP.members, rp.members);
        else existingRP.members = rp.members;

        if (existingRP.web2Object) Object.assign(existingRP.web2Object, rp.web2Object);
        else existingRP.web2Object = rp.web2Object;

        continue;
      }

      (this.relations[relationName] as WebRelationPointer[]).push(rp);
    }
  }

  getRelationPointer(relationName: string, objectGuidId?: string): WebRelationPointer | WebRelationPointer[] {
    const relationPointerOrPointers = this.relations ? this.relations[relationName] : null;
    if (objectGuidId) {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.find((wrp: WebRelationPointer) => {
          return wrp.childGuidId === objectGuidId;
        });
      } else {
        return relationPointerOrPointers?.childGuidId === objectGuidId ? relationPointerOrPointers : null;
      }
    } else {
      return relationPointerOrPointers;
    }
  }

  getRelation(relationName: string, objectGuidId?: string): WebObject | WebObject[] {
    const relationPointerOrPointers = this.relations ? this.relations[relationName] : null;
    if (objectGuidId) {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.find((wrp: WebRelationPointer) => {
          return wrp.childGuidId === objectGuidId;
        })?.web2Object;
      } else {
        return relationPointerOrPointers?.childGuidId === objectGuidId ? relationPointerOrPointers?.web2Object : null;
      }
    } else {
      if (Array.isArray(relationPointerOrPointers)) {
        return relationPointerOrPointers.map((rp: WebRelationPointer) => {
          return rp.web2Object;
        });
      } else {
        return relationPointerOrPointers?.web2Object;
      }
    }
  }

  getSubType(subTypeName: string): WebObject {
    return this.subTypes ? this.subTypes[subTypeName] : null;
  }

  isEmpty(): boolean {
    let hasMembers = false;
    for (const key of Object.keys(this.members || {})) {
      hasMembers = hasMembers || (this.members[key] != null && this.members[key] != '');
    }
    if (hasMembers) return !hasMembers;
    for (const subType of Object.keys(this.subTypes || {})) {
      const subTypeMembers = this.subTypes[subType]?.members;
      for (const key of Object.keys(this.subTypes[subType]?.members || {})) {
        hasMembers = hasMembers || (subTypeMembers[key] != null && subTypeMembers[key] != '');
      }
    }
    return !hasMembers;
  }

  updateOriginalWebObject() {
    this.$originalWebObject = JSON.parse(JSON.stringify(this));
    delete this.$originalWebObject.$originalWebObject;
    if (this.$originalWebObject.web2Status === WebPointerStatus.New) {
      this.$originalWebObject.members = {};
      this.$originalWebObject.relations = {};
    }
  }
}
