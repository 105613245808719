import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WebSkin } from 'app/center/shared/models';
import { UserSession } from 'app/shared/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from '../app';
import { AppService } from '../app/app.service';
import { AuthService } from '../app/auth.service';
import { CheckVersionService } from '../check-version/check-version.service';



@Injectable({
  providedIn: 'root'
})
export class RouteGuardService  {

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private checkVersionService: CheckVersionService,
    private router: Router,
    private sessionService: SessionService,
    private translateService: TranslateService,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const url = state.url;

    this.checkVersionService.check();

    return this.authService.validateSession()
    .pipe(
      map((session: UserSession) => {
        if (session && !session.tempPassword) {
          return true;
        } else if (session && session.tempPassword) {
          if (url.indexOf('/v2/auth/set-password') >= 0) {
            return true;
          } else {
            this.router.navigate(['/v2/auth/set-password']);
            return false;
          }
        } else {
          this.redirectToLogin();
          return false;
        }
      }),
      catchError((error: any) => {
        if (error === this.translateService.instant('No connection to the server...')) {
          this.appService.showToast({
            severity: 'error',
            summary: 'Network Error',
            detail: error,
          });
        }
        return of(false);
      })
    );
  }

  private redirectToLogin() {
    this.sessionService.getWebSkin()
    .subscribe((webSkin: WebSkin) => {
      const clModule = webSkin?.customLogin || 'v2/auth/login';
      if (window.location.pathname.indexOf(clModule) >= 0) {
        this.router.navigate([`/${clModule}`]); // logout
      } else {
        this.router.navigate([`/${clModule}`], { queryParams: { fromUrl: window.location.pathname + window.location.search } }); // logout
      }
    });
  }
}
