import { SolutionProfile, SolutionProfileTemplateSolution, SolutionProfileTemplateSolutionWebFeature } from '../../../shared/models/solution';
import { Tag } from '../../../shared/models/tag.model';
import { WebObject } from './web-base/web-object.model';
import { WorkspaceUIAttributes } from './web-center-types/web-workspace.model';
import { WorkspaceWidgetTemplate } from './workspace-widget-template.model';
import { WorkspaceWidget } from './workspace-widget.model';

export enum WorkspaceType {
  User = 0,
  Template = 1,
  SolutionProfile = 2,
  Management = 4,
}

export class Workspace {

  cid: string;
  guidId: string;
  tags: Tag[];
  name: string;
  activeMode: number;
  color: string;
  demo: boolean;
  shared: boolean;
  readOnly: boolean;
  workspaceType: WorkspaceType;
  adminUser: any;
  solutionProfile: SolutionProfile;
  solutionProfileName: string;
  solutionProfileSiteGuidId: string;
  primaryEnvironmentCID: string;
  primaryEnvironmentGuidId: string;
  primaryEnvironmentName: string;
  environments: any;
  templateSolutions: SolutionProfileTemplateSolution;
  templateSolutionWebFeatures: SolutionProfileTemplateSolutionWebFeature[];
  widgets: WorkspaceWidget[];
  widgetTemplateAccesses: WorkspaceWidgetTemplate[];
  web2Languages: WebObject[];
  web2Environments: WebObject[];
  /** @deprecated **/ web2PrimaryEnvironment: WebObject; // we need to move away from using this...
  noAccessEnforced: boolean;
  environmentUserGuidId: string;
  advancedSearchSelectedSolutionTypeGuidIds: string[];
  accessTreeWorkspace: boolean;
  cacheTimeoutInSeconds: number;
  singleSiteAccess: boolean;
  userAccessTree: any;
  forceAccessTreeWorkspace: boolean;
  allowedUserCopyTypeGuidIds: boolean;
  newUserBehaviour: boolean;
  specialWorkspaceUI: string;
  uiAttributes: WorkspaceUIAttributes;
  languageJson: string;
  userIsAdminUser: boolean;
  userLanguageGuidId: string;
  changeTick: number;
  core3: boolean;

  constructor(item?: Partial<Workspace>) {
    Object.assign(this, item);

    this.widgets = (this.widgets || []).map((x: WorkspaceWidget) => {
      return new WorkspaceWidget(x);
    });

    if (this.web2PrimaryEnvironment) {
      this.web2PrimaryEnvironment = new WebObject(this.web2PrimaryEnvironment);
      delete this.web2PrimaryEnvironment.$originalWebObject;
    }
  }

  getWidget(widgetGuidId: string) {
    return (this.widgets || []).find((w: WorkspaceWidget) => {
      return w.guidId === widgetGuidId;
    });
  }

}
