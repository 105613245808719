import { FieldUtils } from '../utils/field.utils';
import { Field, SolutionField } from './field';
import { FlowBase } from './flow/flow-base.model';
import { FlowVariable } from './flow/flow-variable.model';
import { SolutionDeviceControlType } from './solution-device-control-type.enum';
import { VariableToolbox } from './variable-toolbox';
import { ZebraARType } from './zebra-ar-type.enum';



export class SolutionDeviceControl extends FlowBase {

  deviceControlTypeGuidId: string;
  fields: Field[];

  watchType: ZebraARType;
  watchSolutionDeviceControlGuidId: string;
  zebraARType: ZebraARType;
  zebraARSolutionDeviceControlGuidId: string;

  $variableToolbox: VariableToolbox;

  constructor(item?: Partial<SolutionDeviceControl>) {
    super(item);
  }

  getFieldValue(fieldName: string, variables?: FlowVariable[], texts?: Map<number, string>): any {
    let field = this.getField(fieldName);
    if (field) {
      return field.getValue(variables, texts);
    } else {
      return '';
    }
  }

  getFieldVariable(fieldName: string, variables: FlowVariable[]): FlowVariable {
    let field = this.getField(fieldName);
    if (field) {
      return (field as SolutionField).getVariable(variables);
    } else {
      return null;
    }
  }

  getField(fieldName: string): Field {
    let field = (this.fields || []).find((f: Field) => {
      return (f.name || '').toLowerCase() === fieldName.toLowerCase();
    });
    return FieldUtils.castField(field);
  }

  static getTypeLabel(deviceControlTypeGuidId: string): string {
    switch (deviceControlTypeGuidId) {
      case SolutionDeviceControlType.ARFind:
        return 'ARFind';
      case SolutionDeviceControlType.Calendar1:
        return 'Calendar';
      case SolutionDeviceControlType.Camera1:
        return 'Camera';
      case SolutionDeviceControlType.Checklist1:
        return 'Checklist';
      case SolutionDeviceControlType.Dimensions1:
        return 'Dimensions';
      case SolutionDeviceControlType.External1:
        return 'External';
      case SolutionDeviceControlType.Input1:
        return 'Input';
      case SolutionDeviceControlType.List1:
        return 'List';
      case SolutionDeviceControlType.Location1:
        return 'Location';
      case SolutionDeviceControlType.Menu1:
        return 'Menu';
      case SolutionDeviceControlType.PlatformMainMenu1:
        return 'PlatformMainMenu';
      case SolutionDeviceControlType.Print1:
        return 'Print';
      case SolutionDeviceControlType.QuantityList1:
        return 'QuantityList';
      case SolutionDeviceControlType.RequestClientSetting:
        return 'RequestClientSetting';
      case SolutionDeviceControlType.RequireOnlineHybridState:
        return 'RequireOnlineHybridState';
      case SolutionDeviceControlType.RfidAdd:
        return 'RfidAdd';
      case SolutionDeviceControlType.RfidLocate:
        return 'RfidLocate';
      case SolutionDeviceControlType.RfidInventory:
        return 'RfidInventory';
      case SolutionDeviceControlType.RfidRead:
        return 'RfidRead';
      case SolutionDeviceControlType.RfidRemove:
        return 'RfidRemove';
      case SolutionDeviceControlType.RfidScan:
        return 'RfidScan';
      case SolutionDeviceControlType.Scale1:
        return 'Scale';
      case SolutionDeviceControlType.SmartImage:
        return 'SmartImage';
      case SolutionDeviceControlType.SoG1:
        return 'SoG';
      case SolutionDeviceControlType.SwishQR1:
        return 'SwishQR';
      case SolutionDeviceControlType.SynchronizeHybridDataState:
        return 'SynchronizeHybridDataState';
      case SolutionDeviceControlType.Temperature:
        return 'Temperature';
      case SolutionDeviceControlType.UpdateESL:
        return 'UpdateESL';
      case SolutionDeviceControlType.Visual1:
        return 'Visual';
      default:
        return 'Generic';
    }
  }

}
