<div class="height-100 flex-row gap-1 middle">
  <lc-button *ngIf="refreshClick?.observers?.length"
    (click)="refreshClick.emit()"
    icon="fas fa-sync-alt"
    type="tertiary">
  </lc-button>

  <div *ngIf="lastUpdateDate" class="ag-name-value">
    <span>{{ 'Last Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ lastUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
  </div>

  <div *ngIf="nextUpdateDate" class="ag-name-value">
    <span>{{ 'Next Update' | translate }}</span>:&nbsp;<span class="ag-name-value-value">{{ nextUpdateDate | dfnsFormat: 'HH:mm:ss' }}</span>
  </div>

  <div *ngIf="lastUpdateFailed" class="ag-name-value">
    <span class="fas fa-exclamation-circle" style="color: red"></span>
  </div>
</div>
