import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CenterType, DataResponse, DataSource, TreeRelationSource, TreeSource } from '../../models';
import { ArrayUtils, GuidUtils, StorageUtils } from '../../utils';
import { ApiCenterService } from '../api/api-center.service';
import { LookupService } from '../lookup/lookup.service';
import { StateService } from '../state/state.service';
import { DataRequest, QUERYTYPE_GUIDIDS, QUERYTYPE_TYPESINPROFILE } from './data-request.model';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly URL_SUFFIX = 'api/v0/data';

  constructor(
    private apiService: ApiCenterService,
    private lookupService: LookupService,
    private stateService: StateService,
  ) { }

  /**
   * Method to get LogicWeb stuff.
   * @param {string[]} guidIds Ids of interest
   * @param {boolean} includeData Whether to include webDataSource for corresponding objects or not
   * @param {boolean} resolveDataFromTree Whether to include webDataSource for all tree items
   * @param {boolean} includeTree Whether to include webTreeSources (Full tree NOT designTrees) for corresponding objects or not (USE ONLY FOR ADMIN PURPOSES)
   * @param {boolean} includeDesignTree Whether to include webTreeSources (designTrees) for corresponding objects or not
   * @param {boolean} includeSiteTree Whether to include webTreeSources.siteTree (siteTree / breadcrumbs) for corresponding objects or not
   * @param {boolean} includeTypes Whether to include ALL webTypes for corresponding object or not
   * @param {boolean} includeTrace Whether to include historic data for corresponding objects or not
   * @param {boolean} includeComment Whether to include historic comments for corresponding objects or not
   */
  getByGuids(
    guidIds: string[], includeData: boolean, resolveDataFromTree: boolean,
    includeTree: boolean, includeDesignTree: boolean, includeSiteTree: boolean,
    includeTypes: boolean, includeTrace: boolean, includeComment: boolean,
    includeSubTypes?: boolean,
  ): Observable<DataResponse> {
    let request: DataRequest = {
      adminCallGuidId: GuidUtils.new(),
      includeData: includeData,
      resolveDataFromTree: resolveDataFromTree,
      includeTree: includeTree,
      includeDesignTree: includeDesignTree,
      includeSiteTree: includeSiteTree,
      includeTypes: includeTypes,
      includeSubTypes: includeSubTypes,
      includeTrace: includeTrace,
      includeComment: includeComment,
      queryType: QUERYTYPE_GUIDIDS,
      guidIds: guidIds,
    } as DataRequest;
    return this.stateService.getDataAndTreeState(guidIds[0], this.makeRequest(request));
  }

  /**
   * Method to get LogicWeb stuff.
   * @param {string[]} typeGuidIds Types of interest
   * @param {string} treeGuidId Tree to search types on. Can be the object guidId as that corresponds to a designTreeGuidId
   * @param {string} treeLevels Number of tree levels to return data for. 0 returns all sublevels
   * @param {boolean} includeData Whether to include webDataSource for corresponding objects or not
   * @param {boolean} resolveDataFromTree Whether to include webDataSource for all tree items
   * @param {boolean} includeTree Whether to include webTreeSources (Full tree NOT designTrees) for corresponding objects or not (USE ONLY FOR ADMIN PURPOSES)
   * @param {boolean} includeDesignTree Whether to include webTreeSources (designTrees) for corresponding objects or not
   * @param {boolean} includeTypes Whether to include ALL webTypes for corresponding object or not
   * @param {boolean} includeTrace Whether to include historic data for corresponding objects or not
   */
  getTypesInProfile(
    typeGuidIds: string[], treeGuidId: string, treeLevels: number,
    includeData: boolean, resolveDataFromTree: boolean,
    includeTree: boolean, includeDesignTree: boolean,
    includeTypes: boolean, includeTrace: boolean,
  ): Observable<DataResponse> {
    let request: DataRequest = <DataRequest>{
      includeData: includeData,
      resolveDataFromTree: resolveDataFromTree,
      includeTree: includeTree,
      includeDesignTree: includeDesignTree,
      includeTypes: includeTypes,
      includeTrace: includeTrace,
      queryType: QUERYTYPE_TYPESINPROFILE,
      typeGuidIds: typeGuidIds,
      treeGuidId: treeGuidId,
      treeLevels: treeLevels,
    };

    return this.makeRequest(request);
  }

  private makeRequest(request: DataRequest): Observable<DataResponse> {
    return this.apiService.post<DataResponse>(this.URL_SUFFIX, request)
    .pipe(
      map((dr: DataResponse) => {
        if (dr.webDataSources && dr.webDataSources.length) {
          dr.webDataSources = (dr.webDataSources || []).map(x => new DataSource(x));
          this.lookupService.setDataSource(dr.webDataSources);
        }

        if (dr.webTreeSources && dr.webTreeSources.length) {
          dr.webTreeSources = (dr.webTreeSources || []).map(x => new TreeSource(x));
          this.lookupService.setTreeSource(dr.webTreeSources);

          // the filter tree is being returned with duplicate entries sometimes...
          // TODO: is this really needed anymore?
          for (const ts of dr.webTreeSources) {
            ts.treeRelationSources = ArrayUtils.uniqBy(
              ts.treeRelationSources || [],
              (trs: TreeRelationSource) => {
                return trs.guidId + trs.parentGuidId + trs.childGuidId;
              }
            ).map(x => new TreeRelationSource(x));
          }
        }

        if (dr.webCenterTypes?.length) {
          const webCenterTypes = [];
          for (const ct of dr.webCenterTypes || []) {
            webCenterTypes.push(new CenterType(ct));
          }
          dr.webCenterTypes = webCenterTypes;
          this.lookupService.setLogicType(dr.webCenterTypes);
        }

        if (dr.webCommentSources && dr.webCommentSources.length) {
          this.lookupService.setCommentSources(request.guidIds[0], dr.webCommentSources);
        }

        if (request.guidIds && request.guidIds[0]) {
          StorageUtils.setItem('session', 'data_' + request.guidIds[0], dr);
        }

        return dr;
      })
    );
  }

}
