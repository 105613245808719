<p-dialog
  styleClass="select-center-type-member-dialog"
  [header]="'Select the object Member you want to filter on:' | translate"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <lc-center-type-tree *ngIf="filterSolutionType"
    [centerTypes]="webCenterTypes"
    [groups]="['Members', 'Relations', 'SubTypes']"
    [limitToXLevels]="3"
    membersKey="selectedMember"
    [selectedTreeNodeGuidId]="filterSolutionType?.selectedMember?.guidId"
    [singleSelection]="true"
    [solutionType]="filterSolutionType"
    [solutionTypes]="[]">
  </lc-center-type-tree>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      (click)="dismiss()"
      [label]="'Cancel' | translate"
      type="secondary">
    </lc-button>

    <lc-button
      (click)="confirm()"
      [disabled]="!filterSolutionType?.selectedMember?.guidId"
      [label]="'Confirm' | translate"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
