<div *ngIf="params?.data"
  class="flex-row middle"
  [ngClass]="params?.cellClass"
  [style.height]="'100%'"
  [escape]="false">
  <span *ngIf="params?.group" class="ag-group-expanded" ref="eExpanded">
    <span class="ag-icon ag-icon-tree-open" unselectable="on">
    </span>
  </span>
  <span *ngIf="params?.group" class="ag-group-contracted" ref="eContracted">
    <span class="ag-icon ag-icon-tree-closed" unselectable="on">
    </span>
  </span>

  <span class="width-100 flex-row gap-1 middle"
    (click)="onClick($event)"
    (dblclick)="onDoubleClick($event)">

    <div class="cell-status {{ params.data?.$cellClass ? params.data.$cellClass[params.colDef.colId || params.colDef.field] : '' }}"></div>

    <i *ngIf="params?.type === 'Barcode'"
      class="fas fa-barcode">
    </i>

    <i *ngIf="params?.type === 'QRcode'"
      class="fas fa-qrcode">
    </i>

    <i *ngIf="params?.showCopyIcon"
      class="link far fa-copy"
      (click)="copyToClipboard($event)"
      [pTooltip]="'Copy content...' | translate">
    </i>

    <i *ngIf="params?.iconLeft"
      [ngClass]="params.iconLeft"
      [ngStyle]="params?.iconLeftStyle"
      [pTooltip]="params?.iconLeftTooltip">
    </i>

    <span *ngIf="params?.type === 'GreenRedCircle'"
      class="green-red-circle {{ value ? 'true' : 'false' }}">
    </span>

    <span *ngIf="params?.isDate && !params?.withTime && value"
      class="cell-label"
      [innerHTML]="value | lcDate | highlight:(params?.filter ? params.filter() : '')">
    </span>

    <span *ngIf="params?.isDate && params?.withTime && value"
      class="cell-label"
      [innerHTML]="value | lcDateTime | highlight:(params?.filter ? params.filter() : '')">
    </span>

    <span *ngIf="!params?.isDate && params?.isTime && value"
      class="cell-label"
      [innerHTML]="value | lcTime:params?.withSeconds | highlight:(params?.filter ? params.filter() : '')">
    </span>

    <span *ngIf="params?.type !== 'GreenRedCircle' && !params?.isDate && !params?.isTime && !params?.isLink"
      class="cell-label"
      [innerHTML]="value != null ? (value | highlight:(params?.filter ? params.filter() : '')) : noValueHtml"
      [pTooltip]="params?.tooltip"
      showDelay="500"
      [tooltipPosition]="params?.tooltipPosition || 'bottom'">
    </span>

    <a *ngIf="!params?.isDate && params?.isLink"
      class="link"
      draggable="false"
      href="javascript: void(0);"
      [innerHTML]="value || noValueHtml">
    </a>

    <div *ngIf="params?.iconRight && !params?.iconRightNotFarRight" class="flex-1"></div>

    <i *ngIf="params?.iconRight"
      style="margin-left: .5rem;"
      [ngClass]="params.iconRight"
      [ngStyle]="params?.iconRightStyle"
      [pTooltip]="params?.iconRightTooltip">
    </i>
  </span>
</div>

<div *ngIf="params?.data && isEditable"
  class="cell-editable-border">
</div>
