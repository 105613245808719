<div class="form-field flex-col"
  [class.invalid]="isTouched && invalid"
  style="row-gap: 6px"
  (click)="onTouched()">
  <div class="flex-row gap-1">
    <label *ngIf="label"
      class="label"
      [innerHTML]="label">
    </label>
    <sup *ngIf="required" class="label">*</sup>
  </div>

  <div *ngIf="!editMode" class="value">
    {{ value }}
  </div>

  <p-chips *ngIf="editMode"
    [class.no-wrap]="max === 1"
    [addOnBlur]="true"
    [allowDuplicate]="false"
    [disabled]="disabled"
    [max]="max"
    name="emails"
    [(ngModel)]="values"
    (ngModelChange)="setValueFromChips($event)"
    (onChipClick)="copyToClipboard($event.originalEvent, $event.value)"
    [separator]="separator">
  </p-chips>
</div>
