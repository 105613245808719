import { ungzip } from 'pako';

export const enum ActionCode {
  Ok = 0,
  NoSessionAccess = 1,
  SetPassword = 2,
  RetryLater = 3,
  NeedConfirmation = 4,
  NoAccess = 5,

  StudioInvalidReservation = 1001,
}

export class ApiResponse<T> {

  actionCode: ActionCode;
  success: boolean;
  exceptionString?: string;
  failedReason?: string;

  private _compressedValue?: string;
  private _value?: T;

  constructor(item: any) {
    Object.assign(this, item);
  }

  set compressedValue(val: string) {
    this._compressedValue = val;
  }

  set value(val: T) {
    this._value = val;
  }

  get value(): T {
    if (!this._value && this._compressedValue) {
      // let previousNow = performance.now();
      const valueUint8Array = ungzip(
        Uint8Array.from(
          window.atob(this._compressedValue),
          c => c.charCodeAt(0)
        )
      );
      const decoder = new (window as any).TextDecoder('utf8');
      this._value = JSON.parse(decoder.decode(valueUint8Array));
      // let now = performance.now();
      // console.log(`Time it took to inflate & decode compressedValue: ${now - previousNow}ms`);
    }

    return this._value;
  }

}
