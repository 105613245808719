export enum SpecialValue {

  CameraDeviceControlCaptureSize = 'CameraDeviceControlCaptureSize',
  ClientSettings = 'ClientSettings',
  CompareType = 'CompareType',
  ControlClientSortDefault = 'ControlClientSortDefault',
  ControlClientSortSortOrder = 'ControlClientSortSortOrder',
  Custom = 'Custom',
  DeviceLocationScheduler = 'DeviceLocationScheduler',
  DeviceSolutionMenuSettings = 'DeviceSolutionMenuSettings',
  DeviceSolutionUIAttributes = 'DeviceSolutionUIAttributes',
  FeatureString = 'FeatureString',
  HybridMode = 'HybridMode',
  HybridState = 'HybridState',
  HybridDataSyncronizationState = 'HybridDataSyncronizationState',
  HybridEventQueueSecurityLevel = 'HybridEventQueueSecurityLevel',
  LogicFlowFlowSolutionType = 'LogicFlowFlowSolutionType',
  LogicFlowFlowSolutionTypeNew = 'LogicFlowFlowSolutionTypeNew',
  LogicFlowFlowStudioMode = 'LogicFlowFlowStudioMode',
  LoopType = 'LoopType',
  MathOperation = 'MathCommandMathOperation',
  NativeKeyboard = 'NativeKeyboard',
  NotifyType = 'NotifyType',
  OperationType = 'OperationType',
  QuantityList1LineBehaviour = 'QuantityList1LineBehaviour',
  QuantityList1ScanBehaviour = 'QuantityList1ScanBehaviour',
  ReservePicklistCommandReserveType = 'ReservePicklistCommandReserveType',
  RfidReturnType = 'RfidReturnType',
  RfidUIType = 'RfidUIType',
  SelectOperationType = 'SelectOperationType',
  SolutionDeviceControlButtonsType = 'SolutionDeviceControlButtonsType',
  SolutionDeviceControlHeadType = 'SolutionDeviceControlHeadType',
  SolutionDeviceControlHeadSelectType = 'SolutionDeviceControlHeadSelectType',
  SolutionDeviceControlInput1KeyboardType = 'SolutionDeviceControlInput1KeyboardType',
  SolutionDeviceControlInput1TextBoxType = 'SolutionDeviceControlInput1TextBoxType',
  SolutionDeviceControlInput1TextBoxValueType = 'SolutionDeviceControlInput1TextBoxValueType',
  SolutionDeviceControlInput1VerificationType = 'SolutionDeviceControlInput1VerificationType',
  SolutionDeviceControlInput1VerificationValue = 'SolutionDeviceControlInput1VerificationValue',
  SolutionDeviceControlInput1VerificationInvalidValue = 'SolutionDeviceControlInput1VerificationInvalidValue',
  SolutionDeviceControlMenu1SelectOperationType = 'SolutionDeviceControlMenu1SelectOperationType',
  SolutionDeviceControlScannerEnabledType = 'SolutionDeviceControlScannerEnabledType',
  SolutionDeviceControlScannerEnabledTypeAdvancedOnly = 'SolutionDeviceControlScannerEnabledTypeAdvancedOnly',
  SolutionDeviceControlScannerTypes = 'SolutionDeviceControlScannerTypes',

  VariableCompareCommandCompareType = 'VariableCompareCommandCompareType',
  VariableListFindType = 'VariableListFindType',
  VariableListOperationType = 'VariableListOperationType',
  VariableSetType = 'VariableSetType',


  ZapierApiItemName = 'ZapierApiItemName',

}
