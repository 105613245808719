import { SolutionTypeSubMemberType } from "app/shared/models";
import { CaseUtils, GuidUtils, JsonUtils } from "app/shared/utils";
import { WorkspaceSolutionTypeMember } from "../workspace-solution-type/workspace-solution-type-member.model";
import { WorkspaceSolutionType } from "../workspace-solution-type/workspace-solution-type.model";

export class WebLoadMapCenterTypeRelation {
  loadMapDepth: number;
  relationName: string;
  web2CustomLoadMapCenterType: any;

  constructor(item?: Partial<WebLoadMapCenterTypeRelation>) {
    Object.assign(this, item);
  }
}

export class WebLoadMapCenterType {

  guidId: string;
  centerTypeGuidId: string;
  loadMapDepthStrict: boolean;
  web2Relations: WebLoadMapCenterTypeRelation[];

  constructor(item?: Partial<WebLoadMapCenterType>) {
    Object.assign(this, item);

    this.guidId = GuidUtils.emptyGuid();
    this.web2Relations = (this.web2Relations || []).map((x: WebLoadMapCenterTypeRelation) => {
      return new WebLoadMapCenterTypeRelation(x);
    });
  }

}

export class WebLoadMap {

  includeObjectSite: boolean;
  web2BuildLite: boolean;
  web2LoadMapCenterTypes: WebLoadMapCenterType[];

  constructor(item?: Partial<WebLoadMap>) {
    Object.assign(this, item);

    this.web2LoadMapCenterTypes = (this.web2LoadMapCenterTypes || []).map((x: WebLoadMapCenterType) => {
      return new WebLoadMapCenterType(x);
    });
  }

  /** @deprecated use buildFromWorkspaceSolutionTypeV2 instead*/
  static buildFromWorkspaceSolutionType(solutionType: WorkspaceSolutionType, membersKey: string, solutionTypes?: WorkspaceSolutionType[]): WebLoadMap {
    return this.buildFromWorkspaceSolutionTypeV2(solutionType, [membersKey], solutionTypes);
  }

  /*static buildFromWorkspaceSolutionTypeV2(solutionType: WorkspaceSolutionType, membersKeys: string[], solutionTypes?: WorkspaceSolutionType[], level = 0): WebLoadMap {
    const webLoadMap = new WebLoadMap();
    if (!solutionType) return webLoadMap;
    if (level >= 3) return webLoadMap;

    for (const membersKey of membersKeys || []) {
      const members = JsonUtils.deepFind(solutionType, membersKey, true, []) as WorkspaceSolutionTypeMember[];
      for (const member of members || []) {
        const pathSplit = (member.path || '').split('.');
        if (pathSplit?.length && !!pathSplit[0]) {
          for (let i = 0; i < pathSplit.length; i++) {
            const pathNode = pathSplit[i];
            if (
              (member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Relation && !member.subMembers[i].relationCollection) ||
              member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Collection
            ) {
              const centerTypeGuidId = member.subMembers[i.toString()].fromTypeGuidId;
              webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(pathNode));
            } else if (member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Site) {
              webLoadMap.includeObjectSite = true;
            }
          }
        }

        if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Relation && !member.subMembers[member.subLevels].relationCollection) {
          const centerTypeGuidId = member.subMembers[member.subLevels].fromTypeGuidId;
          webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.name.replace(member.path.replace(/\./g, ' '), '').replace(/ /g, '')));
          if (member.fallbackRelationTypeName) {
            webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.fallbackRelationTypeName));
          }

          const relationSolutionType = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[member.subLevels].toTypeGuidId);
          if (relationSolutionType) {
            const relationWebLoadMap = WebLoadMap.buildFromWorkspaceSolutionTypeV2(relationSolutionType, membersKeys, solutionTypes, ++level);
            for (const web2LoadMapCenterType of relationWebLoadMap?.web2LoadMapCenterTypes || []) {
              for (const web2Relation of web2LoadMapCenterType.web2Relations || []) {
                webLoadMap.addCenterTypeRelation(web2LoadMapCenterType.centerTypeGuidId, web2Relation.relationName);
              }
            }
          }
        } else if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Subcast) {
          const subTypeSolutionType = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[member.subLevels].toTypeGuidId);
          if (subTypeSolutionType) {
            const subTypeWebLoadMap = WebLoadMap.buildFromWorkspaceSolutionTypeV2(subTypeSolutionType, membersKeys, solutionTypes, ++level);
            for (const web2LoadMapCenterType of subTypeWebLoadMap?.web2LoadMapCenterTypes || []) {
              for (const web2Relation of web2LoadMapCenterType.web2Relations || []) {
                webLoadMap.addCenterTypeRelation(web2LoadMapCenterType.centerTypeGuidId, web2Relation.relationName);
              }
            }
          }
        } else if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Site) {
          webLoadMap.includeObjectSite = true;
        }
      }

      let mainLabelPlaceholder = solutionType?.mainLabelPlaceholder;
      let placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
      while (placeholderRelationsIndex >= 0) {
        // if the main label placeholder has relations in it, we need to loadMap them as well...
        mainLabelPlaceholder = mainLabelPlaceholder.substring(placeholderRelationsIndex + 12);
        const relationName = mainLabelPlaceholder.substring(0, mainLabelPlaceholder.indexOf('.'));
        webLoadMap.addCenterTypeRelation(solutionType.baseSolutionTypeGuidId, relationName);

        placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
      };
    }

    return webLoadMap;
  }*/
  static buildFromWorkspaceSolutionTypeV2(solutionType: WorkspaceSolutionType, membersKeys: string[], solutionTypes?: WorkspaceSolutionType[], includeRelationCollections?: boolean): WebLoadMap {
    const webLoadMap = new WebLoadMap();
    if (!solutionType) return webLoadMap;

    for (const membersKey of membersKeys || []) {
      const members = JsonUtils.deepFind(solutionType, membersKey, true, []) as WorkspaceSolutionTypeMember[];
      for (const member of (Array.isArray(members) ? members : [members]) || []) {
        if (member.longName === 'Self Relation') continue;

        const pathSplit = (member.path || '').split('.');
        if (pathSplit?.length && !!pathSplit[0]) {
          for (let i = 0; i < pathSplit.length; i++) {
            const pathNode = pathSplit[i];
            if (
              (member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Relation && !member.subMembers[i].relationCollection)
              || member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Collection
            ) {
              const centerTypeGuidId = member.subMembers[i.toString()].fromTypeGuidId;
              webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(pathNode));
            } else if (member.subMembers[i].subMemberType === SolutionTypeSubMemberType.Site) {
              webLoadMap.includeObjectSite = true;
            }
          }
        }

        if (
          member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Relation
          && (!member.subMembers[member.subLevels].relationCollection || includeRelationCollections)
        ) {
          const centerTypeGuidId = member.subMembers[member.subLevels].fromTypeGuidId;
          webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.longName.replace(member.path.replace(/\./g, ' '), '').replace(/ /g, '')));
          if (member.fallbackRelationTypeName) {
            webLoadMap.addCenterTypeRelation(centerTypeGuidId, CaseUtils.camelize(member.fallbackRelationTypeName));
          }

          const relationSolutionType = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[member.subLevels].toTypeGuidId);
          if (relationSolutionType) {
            const relationWebLoadMap = WebLoadMap.buildFromWorkspaceSolutionTypeV2(relationSolutionType, membersKeys);
            for (const web2LoadMapCenterType of relationWebLoadMap?.web2LoadMapCenterTypes || []) {
              for (const web2Relation of web2LoadMapCenterType.web2Relations || []) {
                webLoadMap.addCenterTypeRelation(web2LoadMapCenterType.centerTypeGuidId, web2Relation.relationName);
              }
            }
          }
        } else if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Subcast) {
          const subTypeSolutionType = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[member.subLevels].toTypeGuidId);
          if (subTypeSolutionType) {
            const subTypeWebLoadMap = WebLoadMap.buildFromWorkspaceSolutionTypeV2(subTypeSolutionType, membersKeys);
            for (const web2LoadMapCenterType of subTypeWebLoadMap?.web2LoadMapCenterTypes || []) {
              for (const web2Relation of web2LoadMapCenterType.web2Relations || []) {
                webLoadMap.addCenterTypeRelation(web2LoadMapCenterType.centerTypeGuidId, web2Relation.relationName);
              }
            }
          }
        } else if (member.subMembers[member.subLevels].subMemberType === SolutionTypeSubMemberType.Site) {
          webLoadMap.includeObjectSite = true;
        }
      }

      let mainLabelPlaceholder = solutionType?.mainLabelPlaceholder;
      let placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
      while (placeholderRelationsIndex >= 0) {
        // if the main label placeholder has relations in it, we need to loadMap them as well...
        mainLabelPlaceholder = mainLabelPlaceholder.substring(placeholderRelationsIndex + 12);
        const relationName = mainLabelPlaceholder.substring(0, mainLabelPlaceholder.indexOf('.'));
        webLoadMap.addCenterTypeRelation(solutionType.baseSolutionTypeGuidId, relationName);

        placeholderRelationsIndex = (mainLabelPlaceholder || '').indexOf('{{relations.');
      };
    }

    return webLoadMap;
  }

  addCenterTypeRelation(centerTypeGuidId: string, relationName: string) {
    let existingCenterType = this.web2LoadMapCenterTypes.find((item: WebLoadMapCenterType) => {
      return item.centerTypeGuidId === centerTypeGuidId;
    });

    if (!existingCenterType) {
      existingCenterType = new WebLoadMapCenterType({ centerTypeGuidId: centerTypeGuidId });
      this.web2LoadMapCenterTypes.push(existingCenterType);
    }

    let existingCenterTypeRelation = existingCenterType.web2Relations.find((item: WebLoadMapCenterTypeRelation) => {
      return item.relationName === relationName;
    });

    if (!existingCenterTypeRelation) {
      existingCenterType.web2Relations.push(new WebLoadMapCenterTypeRelation({ relationName: relationName }));
    }
  }
}
