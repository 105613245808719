<p-dialog
  styleClass="select-solution-type-member-dialog"
  [header]="'Select the object Member you want to filter on:' | translate"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <lc-form-field *ngIf="data?.solutionType"
    appendTo="body"
    [editMode]="true"
    [label]="'Member' | translate"
    [(ngModel)]="data.selectedMember"
    optionLabel="displayName"
    [options]="data?.solutionType.members || []"
    type="dropdown">
  </lc-form-field>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      (click)="dismiss()"
      [label]="'Cancel' | translate"
      type="secondary">
    </lc-button>

    <lc-button
      (click)="confirm()"
      [disabled]="!data?.selectedMember?.guidId"
      [label]="'Confirm' | translate"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
