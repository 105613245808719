import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';



@Injectable({
  providedIn: 'root'
})
export class ApiCenterService {

  constructor(private apiService: ApiService) { }

  get<T>(endpoint: string): Observable<T> {
    return this.apiService.get(environment.apiCenterV1Url, endpoint);
  }

  post<T>(endpoint: string, body: any): Observable<T> {
    return this.apiService.post(environment.apiCenterV1Url, endpoint, body);
  }

}
