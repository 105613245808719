import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AgGridAngular } from 'ag-grid-angular';
import { Events, ProcessCellForExportParams, RowNode } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { WebPointerStatus } from 'app/center-v2/shared/models';
import { GuidUtils } from 'app/shared/utils';
import { format, isDate } from 'date-fns';
import { environment } from 'environments/environment';
import { GridStatusBarRowCountComponent } from './grid-statusbar-row-count/grid-statusbar-row-count.component';
import { GridStatusBarUpdateComponent } from './grid-statusbar-update/grid-statusbar-update.component';

@Component({
  selector: 'lc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit, OnDestroy {

  @ViewChild(AgGridAngular, { static: false }) agGrid: AgGridAngular;

  @Input() animateRows: boolean = true;
  @Input() autoGroupColumnDef: any;
  @Input() autoHeight: boolean;
  @Input() autoSizeColumns: boolean = true;
  @Input() cacheBlockSize: number;
  @Input() chartThemeOverrides: any;
  @Input() set columns(value: any[]) {
    this._columns = value;
    if (!this._columns) return;

    if (!this.disableInitialSort) {
      const hasSortOrIsSortable = (this._columns || []).some(x => x.sort || x.rowDrag) || this.autoGroupColumnDef?.sort;
      if (!hasSortOrIsSortable) {
        const firstColWithHeader = this._columns.find(x => x.headerName && x.sortable !== false);
        if (firstColWithHeader) firstColWithHeader.sort = 'asc';
      }
    }

    setTimeout(() => {
      this.gridReadyWithColumns = true;
      this.cdr.markForCheck();
    }, 100);
  }
  get columns(): any[] {
    return this._columns;
  }
  private _columns: any[];
  @Input() detailCellRenderer: any;
  @Input() detailCellRendererParams: any;
  @Input() detailRowHeight: number;
  @Input() disableInitialSort: boolean;
  @Input() enableCharts: boolean;
  @Input() enablePivotMode: boolean;
  @Input() enableRangeSelection: boolean;
  @Input() enterprise: boolean = true;
  @Input() expandOnRowClick: boolean = true;
  @Input() quickFilter: string;
  @Input() groupDefaultExpanded: number;
  @Input() getDataPath: (params: any) => string[];
  @Input() getGridReady: (params: any) => void;
  @Input() getRowClass: (params: any) => string;
  @Input() getRowHeight: (params: any) => number;
  @Input() getRowId: (params: any) => string = (params: any) => {
    return params.data?.GuidId || params.data?.guidId || params.data?.relationGuidId || params.data?.typeGuidId || params.data?.runCode || GuidUtils.new();
  };
  @Input() getRowStyle: (params: any) => any;
  @Input() getServerSideGroupKey: (params: any) => string;
  @Input() isRowSelectable: (params: any) => boolean;
  @Input() isServerSideGroup: (params: any) => boolean;
  @Input() isServerSideGroupOpenByDefault: (params: any) => boolean;
  @Input() lastUpdateDate: Date;
  @Input() lastUpdateFailed: boolean;
  @Input() nextUpdateDate: Date;
  @Input() masterDetail: boolean;
  @Input() overlayNoRowsTemplate: string = `<span class="ag-overlay-no-rows-center">${this.translateService.instant('No rows to show')}</span>`;
  @Input() pinnedBottomRows: any[];
  @Input() pinnedTopRows: any[];
  @Input() pivotMode: boolean;
  @Input() processCellForClipboard: (params: any) => any;
  @Input() processDataFromClipboard: (params: any) => string[][];
  @Input() set rows(value: any[]) {
    if (
      (!this._rows || !this._rows.length) &&
      value && value.length
    ) {
      this._rows = value;

      this.refresh(false);
    } else {
      this._rows = value;

      this.refresh(true);
    }
  }
  get rows(): any[] {
    return this._rows;
  }
  private _rows: any[];
  @Input() rowClassRules: any;
  @Input() rowDragManaged: boolean;
  @Input() serverSideDataSource: { getRows: (params: any) => void };
  @Input() rowSelection: string;
  @Input() sideBar: boolean | any;
  @Input() singleClickEdit: boolean = true;
  @Input() suppressRowClickSelection: boolean;
  @Input() theme: string = 'ag-theme-balham';
  @Input() treeData: boolean;

  @Output() cellValueChanged = new EventEmitter();
  @Output() filterChanged = new EventEmitter();
  @Output() firstDataRendered = new EventEmitter();
  @Output() modelUpdated = new EventEmitter();
  @Output() refreshClick = new EventEmitter();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() rowDragEnd = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();

  gridGuidId: string;
  gridOptions: any;
  gridReadyWithColumns: boolean;
  isDestroyed: boolean;
  isGridVisible: boolean;

  rowCount: string | number;
  defaultSideBar: any;

  static iconCellRenderer(iconClass: string) {
    let cellHtml = `<i class="cell-icon ${iconClass}"></i>`;

    return cellHtml;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private translateService: TranslateService,
  ) {
    this.gridGuidId = GuidUtils.new();

    this.mouseup = this.mouseup.bind(this);

    // default chartThemeOverrides
    this.chartThemeOverrides = {
      common: {
        axes: {
          category: {
            label: {
              formatter: (params) => {
                const value = params.value.value || params.value;
                if (isDate(value)) {
                  return format(value, 'yyyy-MM-dd' + (value.getMilliseconds() > 0 ? ' HH:mm' : ''));
                } else {
                  return value;
                }
              },
            },
          },
          time: {
            label: {
              formatter: (params: any) => {
                const value = params.value.value || params.value;
                if (value) {
                  return format(value, 'yyyy-MM-dd' + (value.getMilliseconds() > 0 ? ' HH:mm' : ''));
                } else {
                  return value;
                }
              },
            },
          },
        },
        navigator: {
          enabled: true
        },
      },
      area: {
        series: {
          tooltip: {
            renderer: this.getTooltipRenderer,
          },
        },
      },
      bar: {
        series: {
          tooltip: {
            renderer: this.getTooltipRenderer,
          },
        },
      },
      column: {
        series: {
          tooltip: {
            renderer: this.getTooltipRenderer,
          },
        },
      },
      pie: {
        series: {
          tooltip: {
            renderer: this.getTooltipRenderer,
          },
        },
      },
      line: {
        series: {
          tooltip: {
            renderer: this.getTooltipRenderer,
          },
        },
      },
    };
  }

  private getTooltipRenderer(params: any) {
    const xValue = params.xValue?.value || params.xValue;
    const yValue = params.yValue?.value || params.yValue;
    let xFormattedValue = isDate(xValue) ? format(xValue, 'yyyy-MM-dd' + (xValue.getMilliseconds() > 0 ? ' HH:mm' : '')) : xValue;
    let yFormattedValue = isDate(yValue) ? format(yValue, 'yyyy-MM-dd' + (yValue.getMilliseconds() > 0 ? ' HH:mm' : '')) : yValue;
    return {
      content: `<b>${xFormattedValue}:</b> ${yFormattedValue}`,
    };
  }

  ngOnInit() {
    document.body.addEventListener('mouseup', this.mouseup);

    this.gridOptions = {
      autoGroupColumnDef: this.autoGroupColumnDef,
      debug: false,
      defaultColDef: {
        cellClass: 'default',
        editable: false,
        enableValue: this.enablePivotMode, // allow every column to be aggregated (if pivotMode=true)
        enablePivot: this.enablePivotMode, // allow every column to be pivoted (if pivotMode=true)
        flex: 1,
        maxWidth: 512,
        minWidth: 100,
        resizable: true,
        sortable: true,
      },
      icons: {
        save: '<i class="far fa-save"></i>'
      },
      rowModelType: !!this.serverSideDataSource ? 'serverSide' : 'clientSide',
      serverSideDatasource: this.serverSideDataSource,
    };

    this.defaultSideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: this.translateService.instant('Columns'),
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: !this.enablePivotMode,
            suppressValues: !this.enablePivotMode,
            /*suppressRowGroups: true,
            suppressPivots: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true*/
          }
        },
        'filters',
      ],
    };

    if (!this.enterprise || !environment.agGridLicenseKey) return;

    LicenseManager.setLicenseKey(environment.agGridLicenseKey);

    this.gridOptions.defaultColDef.enableRowGroup = true;
    this.gridOptions.statusBar = {
      statusPanels: [
        {
          align: 'left',
          statusPanel: GridStatusBarUpdateComponent,
          statusPanelParams: {
            lastUpdateDate: (params: any) => { return this.lastUpdateDate; },
            nextUpdateDate: (params: any) => { return this.nextUpdateDate; },
            lastUpdateFailed: (params: any) => { return this.lastUpdateFailed; },
            refreshClick: (params: any) => { return this.refreshClick; },
          },
        },
        {
          align: 'center',
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['count', 'sum', 'min', 'max', 'avg']
          },
        },
        {
          align: 'right',
          statusPanel: GridStatusBarRowCountComponent,
        }
      ],
    };
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    document.body.removeEventListener('mouseup', this.mouseup);
  }

  gridReady(params: any) {
    if (!this.agGrid) {
      this.agGrid = params;
    }

    if (this.rows) {
      this.onDataChanged();
    }
    this.agGrid.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.onDataChanged.bind(this));

    if (this.getGridReady) this.getGridReady(params);
  }

  private mouseup(e: Event) {
    if (!this.agGrid) return;

    const agGridContainer: HTMLElement = (this.agGrid as any)._nativeElement;
    if (agGridContainer && !agGridContainer.contains(e.target as HTMLElement) && !this.isElementWithinCustomPopupOrOverlay(e.target)) {
      this.agGrid.api.clearFocusedCell();
    }
  }

  private isElementWithinCustomPopupOrOverlay(el: any) {
    const eDocument = window.document;
    while (el && el !== eDocument.body) {
      if (el.classList.contains('ag-custom-component-popup') || el.classList.contains('p-overlay') || el.parentElement === null) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  }

  refresh(entireRows?: boolean, force = true, gridApi?: any) {
    if (!this.agGrid) return;
    gridApi = gridApi || this.agGrid.api;

    setTimeout(() => {
      if (entireRows || this.serverSideDataSource) {
        gridApi.redrawRows(); // removes the rows from the DOM and adds them again
      } else {
        var firstRowIndex = gridApi.getFirstDisplayedRowIndex();
        var lastRowIndex = gridApi.getLastDisplayedRowIndex();
        const rowNodes: RowNode[] = [];
        gridApi.forEachNodeAfterFilter((rowNode: RowNode, index: number) => {
          if (index >= firstRowIndex && index <= lastRowIndex) {
            rowNodes.push(rowNode);
          }
        });

        gridApi.refreshCells({ rowNodes: rowNodes, force: force });
      }

      this.cdr.markForCheck();
    }, 10);
  }

  refreshRowData(rowsData: any[]) {
    const rowDataTransaction = {
      update: rowsData,
    };

    this.agGrid.api.applyTransaction(rowDataTransaction);
    this.cdr.markForCheck();
  }

  rowClick(params: any) {
    if (this.masterDetail && this.expandOnRowClick) params.node.setExpanded(!params.node.expanded);

    this.rowClicked.emit(params);
  }

  setCellClassModified(params: any) {
    params.data.$cellClass = params.data.$cellClass || {};
    params.data.$cellClass[params.colDef.colId || params.colDef.field] = 'modified fas fa-circle-notch';
    this.refresh(false, !params.cellStartedEdit, params.api);
  }

  setCellClassSuccess(params: any) {
    params.data.$cellClass = params.data.$cellClass || {};
    params.data.$cellClass[params.colDef.colId || params.colDef.field] = 'success fas fa-check';
    this.refresh(false, !params.cellStartedEdit, params.api);
  }

  setCellClassError(params: any) {
    params.data[params.colDef.colId] = params.oldValue;
    params.data.$cellClass = params.data.$cellClass || {};
    params.data.$cellClass[params.colDef.colId || params.colDef.field] = 'error fas fa-exclamation-circle';
    this.refresh(false, !params.cellStartedEdit, params.api);
  }

  exportToCSV(fileName?: string, processCellCallback?: (params: ProcessCellForExportParams) => string) {
    this.agGrid.api.exportDataAsCsv({
      fileName: fileName,
      processCellCallback: processCellCallback,
      columnSeparator: ';',
    });
  }

  onCellValueChanged(params: any) {
    if (params.oldValue !== params.newValue) {
      this.cellValueChanged.emit(params);
    }
  }

  onGridSizeChanged(params: any) {
    this.isGridVisible = params.clientWidth !== 0 && params.clientHeight !== 0;
  }

  onRowGroupOpenend(params: any) {
    if (!this.autoSizeColumns) return;

    this.agGrid.api.autoSizeColumns(this.agGrid.api.getAllDisplayedColumns().slice(0, 1));
  }

  private onDataChanged() {
    const filteredRowCount = this.getFilteredRowCountValue();
    let displayValue: any = this.getTotalRowCountValue();

    if (filteredRowCount !== displayValue) {
      displayValue = `${filteredRowCount} of ${displayValue}`;
    }

    if (!this.rowCount && this.columns) {
      setTimeout(() => {
        if (this.isDestroyed) return;
        if (!this.autoSizeColumns) return;

        const allColumnsExceptTheLast = this.agGrid.api.getAllDisplayedColumns() || [];
        if (!allColumnsExceptTheLast?.length) return;

        allColumnsExceptTheLast.length = allColumnsExceptTheLast.length - 1;
        this.agGrid.api.autoSizeColumns(allColumnsExceptTheLast);
        this.cdr.markForCheck();
      }, 100);
    }

    this.rowCount = displayValue;

    this.cdr.markForCheck();
  }

  getTotalRowCountValue(): number | string {
    if (!this.serverSideDataSource) {
      let totalRowCount = 0;
      this.agGrid.api.forEachLeafNode((node) => {
        const rowHasValues = Object.keys(node.data || {}).length > 0 &&
          Object.keys(node.data).some((key: string) => { return node.data[key] != null; }) &&
          node.data?.web2Status !== WebPointerStatus.Remove;
        return totalRowCount += rowHasValues ? 1 : 0;
      });
      return totalRowCount;
    } else {
      return this.agGrid.api.getDisplayedRowCount() || '?';
    }
  }

  private getFilteredRowCountValue(): number | string {
    if (!this.serverSideDataSource) {
      let filteredRowCount = 0;
      this.agGrid.api.forEachNodeAfterFilter((node) => {
        if (!node.group) {
          const rowHasValues = Object.keys(node.data || {}).length > 0 &&
            Object.keys(node.data).some((key: string) => { return node.data[key] != null; }) &&
            node.data?.web2Status !== WebPointerStatus.Remove;
          filteredRowCount += rowHasValues ? 1 : 0;
        }
      });
      return filteredRowCount;
    } else {
      return this.agGrid.api.getDisplayedRowCount() || '?';
    }
  }

}
