import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceWidgetService } from 'app/center-v2/shared/services';
import { WebSkin } from 'app/center/shared/models';
import { UserSession } from 'app/shared/models';
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { Observable, range, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, retryWhen, zip } from 'rxjs/operators';
import { DateUtils } from '../../utils/date.utils';
import { SessionService } from '../app';
import { AppService } from '../app/app.service';
import { NotificationService } from '../app/notification.service';
import { ActionCode, ApiResponse } from './api-response.model';
import { BrowserUtils } from 'app/shared/utils';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('E130001');
marker('E130002');


class NotReallyAnError {
  constructor(
    public message?: string
  ) {

  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  managementEnvironmentGuidId: string;
  siteGuidId: string;
  targetEnvironmentGuidId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.managementEnvironmentGuidId = params.managementEnvironmentGuidId;
      this.siteGuidId = params.siteGuidId;
      this.targetEnvironmentGuidId = params.targetEnvironmentGuidId;
    });
  }

  get<T>(baseUrl: string, endpoint: string, options?: any): Observable<T> {
    const fullUrl = baseUrl + endpoint;

    return this.http.get(fullUrl, options)
    .pipe(
      map((response: any) => {
        if (!options || options.responseType === 'json') {
          return this.handleResponse(response);
        } else {
          return response
        }
      }),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  post<T>(baseUrl: string, endpoint: string, body: any, options?: any, dontHandleUnknownError?: boolean): Observable<T> {
    const fullUrl = baseUrl + endpoint;
    const workspace = this.workspaceWidgetService.getWorkspace();
    const workspaceGuidId = BrowserUtils.getQueryParams().workspaceGuidId;

    const sessionGuidId = this.sessionService.getSessionGuidId();
    let dto;
    if (body instanceof FormData) {
      dto = body;
      dto = dto.append('webDateTime', DateUtils.nowAsISOString()) || dto;

      if (this.managementEnvironmentGuidId) {
        dto = dto.append('managementEnvironmentGuidId', this.managementEnvironmentGuidId) || dto;
      }
      if (this.targetEnvironmentGuidId) {
        dto = dto.append('targetEnvironmentGuidId', this.targetEnvironmentGuidId) || dto;
      }
      if (sessionGuidId) {
        dto = dto.append('sessionGuidId', sessionGuidId) || dto;
      }
    } else {
      const dtoString = JSON.stringify(body, this.jsonReplacer);

      dto = JSON.parse(dtoString);

      dto = Object.assign(dto, {
        managementEnvironmentGuidId: dto.managementEnvironmentGuidId || this.managementEnvironmentGuidId,
        sessionGuidId: sessionGuidId,
        siteGuidId: dto.siteGuidId || this.siteGuidId,
        solutionProfileGuidId: dto.solutionProfileGuidId || (workspace?.solutionProfile ? workspace.solutionProfile.guidId : undefined),
        targetEnvironmentGuidId: !dto.solutionEnvironmentGuidId ? dto.targetEnvironmentGuidId || this.targetEnvironmentGuidId || undefined : undefined,
        webDateTime: DateUtils.nowAsISOString(),
        workspaceGuidId: dto.workspaceGuidId || workspaceGuidId,
        adminCallGuidId: dto.adminCallGuidId || ((dto.workspaceGuidId || workspaceGuidId) ? undefined : GuidUtils.new()),
      });
    }

    return this.http.post(fullUrl, dto, options)
    .pipe(
      map((response: any) => {
        if (!options || options.responseType === 'json') {
          return this.handleResponse(response, dontHandleUnknownError);
        } else {
          return response
        }
      }),
      retryWhen(this.incrementalBackOffRetry),
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  private handleResponse<T>(response: ApiResponse<T>, dontHandleUnknownError?: boolean) {
    response = new ApiResponse(response);
    if (response.success) return response.value;

    if (response.actionCode === ActionCode.NoSessionAccess) {
      this.redirectToLogin();

      this.sessionService.get()
      .subscribe((session: UserSession) => {
        if (session) {
          this.notificationService.info(
            this.translateService.instant('Session Expired'),
            this.translateService.instant('Please login again.'),
          );
        }
      });

      throw new NotReallyAnError('No Session Access');
    } else if (response.actionCode === ActionCode.NoAccess) {
      this.notificationService.error(
        this.translateService.instant('Error'),
        this.translateService.instant('No Access / Not Allowed'),
      );
      throw new NotReallyAnError('No Access.');
    } else if (response.actionCode === ActionCode.SetPassword) {
      this.router.navigate(['/v2/auth/set-password']);
      throw new NotReallyAnError('Set Password');
    } else if (response.actionCode === ActionCode.RetryLater) {
      throw new NotReallyAnError('Retry');
    } else if (response.actionCode === ActionCode.StudioInvalidReservation) {
      throw new Error('Invalid Solution Reservation.');
    } else if (
      (response.value as any)?.notAllowedReason
      || (response.value as any)?.failedReason
      || response?.failedReason
    ) {
      this.notificationService.error(
        this.translateService.instant('Error'),
        this.translateService.instant(
          (response.value as any)?.notAllowedReason
          || (response.value as any)?.failedReason
          || response?.failedReason
        ),
      );
      throw new NotReallyAnError('Server Error');
    } else if (dontHandleUnknownError) {
      return response.value;
    } else {
      console.warn(response.exceptionString || '<empty exceptionString>');
      throw new Error('A system error has occured.');
    }
  }

  private redirectToLogin() {
    this.sessionService.getWebSkin()
    .subscribe((webSkin: WebSkin) => {
      const clModule = webSkin?.customLogin || 'v2/auth/login';
      if (window.location.pathname.indexOf(clModule) >= 0) {
        this.router.navigate([`/${clModule}`]); // logout
      } else {
        this.router.navigate([`/${clModule}`], { queryParams: { fromUrl: window.location.pathname + window.location.search } }); // logout
      }
    });

  }

  private incrementalBackOffRetry(errors: Observable<any>): Observable<any> {
    return errors
    .pipe(
      zip(range(1, 4)),
      mergeMap(([error, i]) => {
        let stopRetrying: boolean = error.message !== 'Retry' || i > 3;
        if (stopRetrying) {
          return throwError(() => error.message !== 'Retry' ? error : 'Server Error');
        } else {
          return timer(i * 1000);
        }
      })
    );
  }

  private handleError(error: any): Observable<any> {
    if (error.status === 0) {
      return throwError(() => this.translateService.instant('No connection to the server...'));
    }

    let errorMsg: string;
    if (error.error?.errorCode || error.error?.errorText) {
      const errorCodeTranslated = error.error.errorCode ? this.translateService.instant(error.error.errorCode) : error.error.errorCode;
      errorMsg = errorCodeTranslated !== error.error.errorCode ? errorCodeTranslated : error.error.errorText;
    } else {
      errorMsg = error.json ? error.json().message : error.message;
    }

    if (!(error instanceof NotReallyAnError)) {
      this.appService.showDefaultErrorToast(errorMsg || 'Server Error', true);
    }
    return throwError(() => errorMsg);
  }

  private jsonReplacer(name: string, val: any) {
    if (
      (name || '').toString().indexOf('$') === 0 ||
      val === undefined
    ) {
      return undefined; // remove from result
    } else {
        return val; // return as is
    }
  }

}
