import { Injectable } from '@angular/core';
import { StorageUtils, WebSkinUtils } from 'app/shared/utils';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SessionService } from '.';
import { UserSession } from '../../models';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterV3Service } from '../api/api-center-v3.service';
import { addSeconds } from 'date-fns';
import { ApiCenterV3Scope } from '../api/api-center-v3-scope.enum';




@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly urlV2ValidateSession = 'centerv2/user/validatesession';
  private readonly urlV3UpgradeSession = 'user/upgradesession';
  private readonly urlV2UserSuffixPlaceholder = 'user/{what}';
  private readonly urlCenterV2UserSuffixPlaceholder = 'centerv2/user/{what}';

  constructor(
    private apiV2Service: ApiCenterV2Service,
    private apiV3Service: ApiCenterV3Service,
    private sessionService: SessionService,
  ) { }

  loginWithEmail(email: string, password: string): Observable<UserSession> {
    const webSkin = WebSkinUtils.getWebSkinForCurrentUrl(window.location.href) || WebSkinUtils.getDefaultWebSkin();

    return this.apiV2Service.post<UserSession>(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'logon/logon'),
      {
        email: email,
        password: password,
        baseUri: this.getBaseUrl(),
        webSkinGuidId: webSkin.guidId,
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session, email);
      })
    );
  }

  loginWithGuid(userGuidId: string, password: string): Observable<UserSession> {
    const webSkin = WebSkinUtils.getWebSkinForCurrentUrl(window.location.href) || WebSkinUtils.getDefaultWebSkin();

    return this.apiV2Service.post<UserSession>(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'logon/logon'),
      {
        baseId: userGuidId,
        password: password,
        baseUri: this.getBaseUrl(),
        webSkinGuidId: webSkin.guidId,
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session);
      })
    );
  }

  loginAsUser(userGuidId: string): Observable<UserSession> {
    return this.apiV2Service.post<UserSession>(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'logon/logonasuser'),
      {
        userGuidId: userGuidId,
      }
    ).pipe(
      mergeMap((session: UserSession) => {
        return this.handleLoginResponse(session);
      })
    );
  }

  private handleLoginResponse(session: UserSession, email?: string): Observable<UserSession> {
    if (!session?.authenticated) throw Error('Invalid Credentials');

    if (email) session.$email = email;

    return this.upgradeSession(session);
  }

  logout(): Observable<UserSession> {
    return this.apiV2Service.post<UserSession>(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'logon/logoff'),
      {}
    ).pipe(
      mergeMap((logoffSession: any) => {
        let session: UserSession = null;
        if (logoffSession.hasParentSession && logoffSession.parentUserSession) {
          session = logoffSession.parentUserSession;
        } else {
          session = this.sessionService.instant();
          session.guidId = undefined;
          session.userGuidId = undefined;
        }

        session.hasParentSession = false;
        return this.sessionService.set(session);
      })
    );
  }

  fullLogout() {
    StorageUtils.clear('local');
    StorageUtils.clear('session');
    this.sessionService.set(undefined);
  }

  validateSession(): Observable<UserSession> {
    return this.apiV2Service.post(
      this.urlV2ValidateSession,
      {}
    ).pipe(
      mergeMap((sessionV2: any) => {
        return this.sessionService.get()
        .pipe(
          mergeMap((currentSession: UserSession) => {
            if (!currentSession) return of(null);

            currentSession.hasParentSession = sessionV2.hasParentSession;
            currentSession.inConfuscateMode = sessionV2.inConfuscateMode;
            currentSession.permissionGuidId = sessionV2.permissionGuidId;

            if (!currentSession.userAccessToken || new Date(currentSession.userAccessTokenExpiredDate) < new Date()) {
              return this.upgradeSession(currentSession);
            } else {
              return this.sessionService.set(currentSession);
            }
          }),
        );
      })
    );
  }

  private upgradeSession(currentSession: UserSession): Observable<UserSession> {
    if (!currentSession) return of(null);

    return this.apiV3Service.post(
      ApiCenterV3Scope.None,
      this.urlV3UpgradeSession,
      {
        center2SessionGuidId: currentSession.guidId
      }
    ).pipe(
      mergeMap((sessionV3: any) => {
        let now = new Date();
        currentSession.userAccessToken = sessionV3.access_token;
        currentSession.userAccessTokenExpiredDate = addSeconds(now, sessionV3.expires_in).toISOString();
        currentSession.userRefreshToken = sessionV3.refresh_token;
        currentSession.userRefreshTokenExpiredDate = addSeconds(now, sessionV3.refresh_token_expires_in).toISOString();
        return this.sessionService.set(currentSession);
      })
    );
  }

  setPassword(password: string): Observable<boolean> {
    return this.apiV2Service.post(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'setpassword'),
      {
        password: password,
        baseUri: this.getBaseUrl(),
      }
    ).pipe(
      map((result: boolean) => {
        this.sessionService.get()
        .subscribe((session: UserSession) => {
          session.tempPassword = false;
          this.sessionService.set(session).subscribe();
        });

        return result;
      })
    );
  }

  generatePassword(userGuidId: string, emailUser: boolean): Observable<string> {
    return this.apiV2Service.post(
      this.urlCenterV2UserSuffixPlaceholder.replace('{what}', 'generatenewpassword'),
      {
        userGuidId: userGuidId,
        emailUser: emailUser,
        baseUri: this.getBaseUrl(),
      }
    ).pipe(
      map((response: any) => {
        return response.tempPassword ? response.tempPassword : undefined;
      })
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.apiV2Service.post(
      this.urlV2UserSuffixPlaceholder.replace('{what}', 'forgotpassword'),
      {
        email: email,
        baseUri: this.getBaseUrl(),
      }
    );
  }

  private getBaseUrl(): string {
    return window.location.origin;
  }

}
