export enum SolutionDeviceControlType {

  ARFind = 'a1209ec1-621d-49b0-9cc7-3097686075d5',
  Calendar1 = '640ae316-5e57-4b04-aac0-e26ca76c8048',
  Camera1 = '9c846398-6c15-4b54-800a-ca46e88d6f2f',
  Checklist1 = '2a043d2c-8235-45cd-b7c8-5c187f238fe5',
  Dimensions1 = 'd480d514-2711-4b25-9e48-e08e1d436476',
  External1 = '9366b186-09db-4030-a2ae-89fa10424cdb',
  Input1 = '3ef915bb-aa12-4fbc-b917-b88d2782ec68',
  List1 = '6f063ce5-6641-43fb-b75e-8fe58d6a3357',
  Location1 = 'c9f819d6-8926-4dbc-9dea-fe148f671cba',
  Menu1 = '02cc92e2-7eeb-4a0a-b793-25fad20d05d8',
  PlatformMainMenu1 = '94cf0c85-e873-4162-8494-22f3fcf93a2b',
  Print1 = '5bd31797-ecb8-4d86-af4d-dded51e9d919',
  QuantityList1 = 'c89734c3-305e-4cf7-ba0b-0c6974775bcc',
  RequestClientSetting = '1474a41c-3725-4dc2-ba81-96d7604e0e5b',
  RequireOnlineHybridState = 'd8244ddf-99c4-4050-8a49-6ca0a8ffe165',
  RfidAdd = '864be200-5cb9-41e2-844f-c5c7714ff63b',
  RfidInventory = 'd40b05cc-20ac-46a8-aecc-aa8028d8d04c',
  RfidLocate = '6444a180-2cbe-4a25-994e-9f69d26fbfbb',
  RfidRead = '86a042d1-4d60-46a7-b2fe-93151e5d9af2',
  RfidRemove = 'ababa5ed-e7bb-4bf2-afb4-9c1777b463a4',
  RfidScan = '70af27de-b367-4fe6-8d0b-30303a168076',
  Scale1 = 'f662a187-6cd3-4120-945d-a2b75384ebfc',
  SynchronizeHybridDataState = 'fa56eded-35a3-4aa8-b679-9b5da3ff66bd',
  SmartImage = 'bd58fbd5-9d97-4444-84d8-b3a7804813b1',
  SoG1 = 'cc0b6cd3-727b-470e-8427-de8d8b1d1bb0',
  SwishQR1 = '08402f22-38c3-4fc5-bc3a-a77ac79c824f',
  Temperature = '2b4535b0-ae1c-455c-ad8c-c9bb15e417a5',
  UpdateESL = 'aa7fec61-dfa2-493c-b8e6-1005bcfc0c3a',
  Visual1 = '114b908c-68c6-4336-ac3a-4a33ab7181cf',

}