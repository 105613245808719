import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebLoadMap, WebObject, WebRelationPointer, WebResponse, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { GenericService } from 'app/center-v2/shared/services';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  standalone: true,
  imports: [
    AppSharedV2Module,
    InputTextModule,
  ],
  selector: 'lc-form-field-relation-productfinderstoryitemview',
  templateUrl: 'form-field-relation-productfinderstoryitemview.component.html',
  styleUrls: ['./form-field-relation-productfinderstoryitemview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldRelationProductFinderStoryItemViewComponent),
    multi: true
  }],
})
export class FormFieldRelationProductFinderStoryItemViewComponent implements ControlValueAccessor, AfterViewInit {

  readonly defaultHtml =
`<style>
  .story-item-view {
    background-color: lightblue;
    height: 100%;
  }
  .selector {
    ul {
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;

      display: flex;
      row-gap: 1rem;
      column-gap: 1rem;

      li {
        background-color: #007ad9;
        border-radius: 4px;
        color: white;
        padding: 0.5rem 1rem;
      }
    }
  }
</style>

<div class="story-item-view">
  <div class="height-100 flex-col center middle">
    <div class="selector">
      {{selector}}
    </div>
  </div>
</div>`;


  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() max: number;
  @Input() placeholder: string;
  @Input() relationName?: string;
  @Input() relationParentWebObject?: WebObject;
  @Input() relationSolutionType?: WorkspaceSolutionType;
  @Input() relationWebObject?: WebObject;
  @Input() required: boolean;
  @Input() separator: string;

  @Output() labelIconRightClick = new EventEmitter<MouseEvent>();

  onChange: (x: any) => {};
  onTouched: () => {};

  htmlChanged = new Subject<string>();
  invalid: boolean;
  isFocused = false;
  isTouched: boolean;
  previewHTML: SafeHtml;
  value: string;
  values: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private genericService: GenericService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.htmlChanged
    .pipe(
      debounceTime(300)
    )
    .subscribe(() => { this.onHtmlChange(); });
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value === value) return;

    this.value = value;

    this.cdr.markForCheck();

    setTimeout(() => {
      this.cdr.markForCheck();
    }, 100);
  }

   // From ControlValueAccessor interface
   registerOnChange(fn: any) {
    this.onChange = (value: any) => {
      this.invalid = this.required && (value == null || value === '' || (!(value instanceof WebObject) && !value?.length));
      return fn(value);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = () => {
      this.isTouched = true;
      return fn();
    };
  }

  ngAfterViewInit() {
    this.refresh();
  }

  refresh() {
    if (!this.relationWebObject) return;

    this.genericService.relationV2(this.relationParentWebObject, 'ports', new WebLoadMap())
    .subscribe({
      next: (response: WebResponse) => {
        this.relationParentWebObject.relations.ports = response.getRelationPointer('ports');
        this.relationWebObject.members.text = this.relationWebObject.members.text || this.defaultHtml;
        this.onHtmlChange();

        this.cdr.markForCheck();
      }, error: (error: any) => {
        this.cdr.markForCheck();
      }
    });
  }

  onHtmlChange() {
    const ports = (this.relationParentWebObject.relations.ports as WebRelationPointer[] || [])
    .map(wrp => wrp.web2Object);

    if ((this.relationWebObject?.members?.text || '').indexOf('<script>') >= 0) {
      this.notificationService.warn(
        this.translateService.instant('Not allowed'),
        this.translateService.instant('Script tags are not allowed')
      )
    }
    let html = (this.relationWebObject?.members?.text || '')
    .replace(
      /<script>/g,
      '<div>'
    )
    .replace(
      /<\/script>/g,
      '</div>'
    )
    .replace(
      '{{selector}}',
      '<ul>' + ports.map(x => `<li id="${x.members.staticValue}"><a>${x.members.name}</a></li>`).join('') + '</ul>'
    )
    this.previewHTML = this.domSanitizer.bypassSecurityTrustHtml(html);

    this.cdr.markForCheck();
  }

}
