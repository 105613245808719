import { ObjectOrValue } from 'app/shared/models/solution-type/solution-type-member.model';
import { ArrayUtils, CaseUtils, GuidUtils, JsonUtils } from 'app/shared/utils';
import { WorkspaceSolutionType, WorkspaceSolutionTypeMember } from '..';
import { WebCenterTypeMember, WebCenterTypeMemberSpecialValue, WebCenterTypeMemberSpecialValueType } from './web-center-type-member.model';
import { WebCenterTypeRelation } from './web-center-type-relation.model';


export class WebCenterType {

  guidId: string;
  typeGuidId: string;
  centerTypeId: number;
  name: string;
  dataName: string;
  access: any;
  isTreeRoot: boolean;
  isRootUntyped: boolean;
  rootTypeGuidId: string;
  defaultTypeOfString: string;
  altDefaultTypeOfString: string;
  web2Name: string;
  web2NewBehaviour: any;
  web2NewSiteBehaviour: any;
  web2Ready: boolean;
  requireEnvironment: boolean;
  defaultEnvironmentGuidId: string;
  environmentOnly: boolean;
  isSysType: boolean;
  sysIdName: string;
  sysIdObjectTypeMemberId: string;
  isLanguageType: boolean;
  parentRelationGuidId: string;
  version: string;
  tick: number;

  members: WebCenterTypeMember[];
  relations: WebCenterTypeRelation[];
  collections: any[];
  subTypeGuidIds: string[];
  structureType: boolean;
  structureParentMemberId?: number;
  structureParentRelationTypeGuidId?: string;


  constructor(item?: Partial<WebCenterType>) {
    Object.assign(this, item);

    this.members = (this.members || []).map((ctm: WebCenterTypeMember) => {
      return new WebCenterTypeMember(ctm);
    });

    this.relations = (this.relations || []).map((ctr: WebCenterTypeRelation) => {
      return new WebCenterTypeRelation(ctr);
    });

    this.addAdditionalClientSideInfo();
  }

  static getListFromWorkspaceSolutionType(solutionTypes: WorkspaceSolutionType[], solutionType: WorkspaceSolutionType, membersKeys: string[], level = 0): string[] {
    const result = [];

    for (const membersKey of membersKeys || []) {
      const members = JsonUtils.deepFind(solutionType, membersKey, true, []) as WorkspaceSolutionTypeMember[];
      for (const member of members || []) {
        if (member.objectOrValue === ObjectOrValue.Object) {
          const nestedST = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[0].toTypeGuidId);
          if (nestedST && level < 3) result.push(...WebCenterType.getListFromWorkspaceSolutionType(solutionTypes, nestedST, membersKeys, ++level));
        }

        for (let i = 0; i <= member.subLevels; i++) {
          if (!GuidUtils.isNullOrEmpty(member.subMembers[i].fromTypeGuidId)) result.push(member.subMembers[i].fromTypeGuidId);
          if (!GuidUtils.isNullOrEmpty(member.subMembers[i].toTypeGuidId)) result.push(member.subMembers[i].toTypeGuidId);
        }
      }
    }

    return ArrayUtils.uniqBy(result, x => x);
  }

  private addAdditionalClientSideInfo() {
    // need to add an extra $key to the member objects -> lowercase name
    this.members = (this.members || [])
    .map((m: WebCenterTypeMember) => {
      m.$key = CaseUtils.camelize(m.name, true);
      return m;
    });

    for (const relation of this.relations || []) {
      for (const member of relation.relationMembers || []) {
        member.$key = (member.name || '').toLowerCase();
        this.mapServerTypesAndTexts(member);
      }
    }

    // then, I map out the server types to HTML types...or special types
    for (const member of this.members || []) {
      this.mapServerTypesAndTexts(member);
    }

    // for (const relation of this.relations || []) {
    //   if (relation.relationMembers) {
    //     for (const member of relation.relationMembers || []) {
    //       this.mapServerTypesAndTexts(member);
    //     }
    //   }
    // }
  }

  private mapServerTypesAndTexts(member: WebCenterTypeMember) {
    if (member.$fieldType) return; // already mapped out

    if (member.specialValues && member.specialValuesType === WebCenterTypeMemberSpecialValueType.Normal) {
      member.$fieldType = 'dropdown';
      member.$fieldOptions = (member.specialValues || [])
      .map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (member.specialValues && member.specialValuesType === WebCenterTypeMemberSpecialValueType.IsFlags) {
      member.$fieldType = 'multi-select-flags';
      member.$fieldOptions = (member.specialValues || [])
      .map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (member.specialMemberType === 'DateTime') {
      member.$fieldType = 'date';
    } else if (member.specialMemberType === 'DayIndex') {
      member.$fieldType = 'date';
    } else if (member.specialMemberType === 'TimeIndex') {
      member.$fieldType = 'time';
    } else if (member.specialMemberType === 'TimeSpanHour') {
      member.$fieldType = 'time';
    } else if (member.specialMemberType === 'WizardJson' || member.specialMemberType === 'WizardUI') {
      member.$fieldType = 'json';
    } else if (member.specialMemberType) {
      member.$fieldType = member.specialMemberType.toLowerCase();
    } else {
      switch (member.nativeType) {
        case 'System.String':
          member.$fieldType = 'text';
          break;
        case 'System.Int32':
        case 'System.Int64':
        case 'System.Decimal':
        case 'System.Double':
          member.$fieldType = 'number';
          break;
        case 'System.Boolean':
          member.$fieldType = member.allowNull ? 'checkbox3' : 'checkbox';
          break;
      }
    }
  }

}
