import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import Quill from 'quill';
import { FormFieldBackofficePlatformReportResultMethodModule } from './components/form-field-backoffice-platform-report-result-method/form-field-backoffice-platform-report-result-method.module';
import { FormFieldDaysOfWeekModule } from './components/form-field-days-of-week/form-field-days-of-week.module';
import { FormFieldEmailsModule } from './components/form-field-emails/form-field-emails.module';
import { FormFieldGeoJsonGeometryModule } from './components/form-field-geojsongeometry/form-field-geojsongeometry.module';
import { FormFieldHtmlComponent } from './components/form-field-html/form-field-html.component';
import { FormFieldJsonModule } from './components/form-field-json/form-field-json.module';
import { FormFieldRelationProductFinderStoryItemViewComponent } from './components/form-field-relation-productfinderstoryitemview/form-field-relation-productfinderstoryitemview.component';
import { FormFieldRelationModule } from './components/form-field-relation/form-field-relation.module';
import { FormFieldTimeSpanHoursModule } from './components/form-field-time-span-hours/form-field-time-span-hours.module';
import { FormFieldTimeSpanSecondsModule } from './components/form-field-time-span-seconds/form-field-time-span-seconds.module';
import { FormFieldWarehouseLocationArticleTypeModule } from './components/form-field-warehouse-location-article-type/form-field-warehouse-location-article-type.module';
import { FormFieldWorkScheduleModule } from './components/form-field-work-schedule/form-field-work-schedule.module';
import { FormFieldComponent } from './form-field.component';


export class InitializeQuill {
  constructor() {
    const Inline = Quill.import('blots/inline');
    class BoldBlot extends Inline { };
    (BoldBlot as any).blotName = 'bold';
    (BoldBlot as any).tagName = 'b';

    class ItalicBlot extends Inline { }
    (ItalicBlot as any).blotName = 'italic';
    (ItalicBlot as any).tagName = 'i';

    class StrikeBlot extends Inline { }
    (StrikeBlot as any).blotName = 'strike';
    (StrikeBlot as any).tagName = 'strike';

    Quill.register(BoldBlot);
    Quill.register(ItalicBlot);
    Quill.register(StrikeBlot);
  }
}

new InitializeQuill();
@NgModule({
  declarations: [
    FormFieldComponent,
  ],
  exports: [
    FormFieldComponent,
  ],
  imports: [
    AppSharedV2Module,
    NgxMaskDirective,
    CalendarModule,
    CheckboxModule,
    ColorPickerModule,
    DropdownModule,
    EditorModule,
    FormFieldDaysOfWeekModule,
    FormFieldBackofficePlatformReportResultMethodModule,
    FormFieldGeoJsonGeometryModule,
    FormFieldHtmlComponent,
    FormFieldJsonModule,
    FormFieldEmailsModule,
    FormFieldRelationModule,
    FormFieldRelationProductFinderStoryItemViewComponent,
    FormFieldTimeSpanHoursModule,
    FormFieldTimeSpanSecondsModule,
    FormFieldWarehouseLocationArticleTypeModule,
    FormFieldWorkScheduleModule,

    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    MultiSelectModule,
    RatingModule,
    SelectButtonModule,
    TriStateCheckboxModule,
  ],
  providers: [
    provideNgxMask(),
  ],
})
export class FormFieldModule { }
