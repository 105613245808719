import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';


export enum ButtonType {
  Clear = 'clear',
  Danger = 'danger',
  Fab = 'fab',
  Primary = 'primary',
  PrimaryFull = 'primary-full',
  Secondary = 'secondary',
  SecondaryFlat = 'secondary-flat',
  SecondaryRound = 'secondary-round',
  Success = 'success',
  SuccessFull = 'success-full',
  Tertiary = 'tertiary',
  Text = 'text',
  TextFlat = 'text-flat',
  Warning = 'warning',

}

@Component({
  selector: 'lc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {

  @Input() set type(value: ButtonType) {
    this._type = value;

    this.refresh();
  }
  get type(): ButtonType {
    return this._type;
  }
  private _type: ButtonType;

  @Input() async: boolean;
  @Input() disabled: boolean;
  @Input() icon: string;
  @Input() iconPos: 'bottom' | 'top' | 'left' | 'right' = 'right';
  @Input() isFormSubmit: boolean;
  @Input() label: string;

  @Output() click = new EventEmitter<any>();

  asyncDisabled: boolean;
  styleClass: string;
  link: boolean;
  outlined: boolean;
  rounded: boolean;
  severity: string;
  text: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  private refresh() {
    switch(this.type) {
      case ButtonType.Danger:
        this.severity = 'danger';
        break;
      case ButtonType.Clear:
        this.text = true;
        this.severity = 'primary';
        break;
      case ButtonType.Fab:
        this.severity = 'success';
        this.styleClass = 'fab';
        break;
      case ButtonType.Primary:
        this.severity = 'primary';
        break;
      case ButtonType.PrimaryFull:
        this.severity = 'primary';
        this.styleClass = 'full';
        break;
      case ButtonType.Secondary:
      case ButtonType.SecondaryFlat:
        this.severity = 'secondary';
        break;
      case ButtonType.Tertiary:
        this.outlined = true;
        this.severity = 'secondary';
        break;
      case ButtonType.SecondaryRound:
        this.rounded = true;
        this.severity = 'secondary';
        break;
      case ButtonType.Success:
        this.severity = 'success';
        break;
        case ButtonType.SuccessFull:
        this.severity = 'success';
        this.styleClass = 'full';
        break;
      case ButtonType.Warning:
        this.severity = 'warning';
        break;
      default:
        this.styleClass =  '';
    }
  }

  onClick(event?: Event) {
    if (event) event.stopPropagation();

    if (this.disabled || this.asyncDisabled) return;

    if (this.async) {
      this.asyncDisabled = true;
      this.cdr.markForCheck();

      this.click.next(this.onClickCompleted.bind(this));
    } else {
      this.click.next(event as any);
    }
  }

  onClickCompleted() {
    setTimeout(() => {
      this.asyncDisabled = false;
      this.cdr.markForCheck();
    }, 10);
  }

}
