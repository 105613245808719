<div class="form-field flex-col gap-1"
  [class.invalid]="isTouched && invalid"
  (click)="onTouched()">
  <div class="flex-row gap-1">
    <i class="fad fa-chart-network">
    </i>

    <label *ngIf="label"
      class="label"
      [innerHTML]="label">
    </label>
    <sup *ngIf="required" class="label">*</sup>

    <i *ngIf="labelIconRight"
      [ngClass]="labelIconRight"
      (click)="labelIconRightClick.emit($event)"
      [pTooltip]="labelIconRightTooltip">
    </i>
  </div>

  <div *ngIf="relationWebObject"
    class="flex-col gap-2"
    style="border: 1px solid gainsboro; padding: 0.5rem;">
    <div class="field flex-col gap-1">
      <label class="label">
        {{ 'Headline' | translate }}
      </label>

      <input
        pInputText
        class="input"
        [disabled]="disabled"
        [(ngModel)]="relationWebObject.members.headline"
        [required]="required"
        type="text" />
    </div>

    <div class="flex-row gap-1">
      <div class="flex-1 flex-col gap-1">
        <label class="label">
          {{ 'HTML' | translate }} (use <span ngNonBindable>{{selector}}</span> for the list of possible outputs)
        </label>

        <textarea pInputText
          class="input"
          style="width: 100%;"
          [(ngModel)]="relationWebObject.members.text"
          (ngModelChange)="htmlChanged.next($event)"
          [rows]="16">
        </textarea>
      </div>

      <div class="flex-1 flex-col gap-1">
        <label class="label">
          {{ 'Preview' | translate }}
        </label>

        <div class="flex-1"
          style="display: contents;"
          [innerHTML]="previewHTML">
        </div>
      </div>
    </div>
  </div>
</div>
