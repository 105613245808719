<div class="form-field flex-col"
  style="row-gap: 4px"
  (click)="onTouched()">
  <label *ngIf="label"
    class="label"
    [innerHTML]="label">
  </label>

  <div *ngIf="!editMode"
    class="value">
    <div [innerHTML]="value != null ? getValueLabel() : noValueHtml">
    </div>
  </div>

  <div *ngIf="editMode">
    <div class="flex-row">
      <div class="flex-1 flex-col gap-1">
        <div class="label">{{ 'Years' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="years"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>

      <div class="flex-1 flex-col gap-1">
        <div class="label">{{ 'Months' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="months"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>

      <div class="flex-1 flex-col gap-1">
        <div class="label">{{ 'Days' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="days"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>

      <div class="flex-1 flex-col gap-1">
        <div class="label">{{ 'Hours' | translate }}:</div>

        <input pInputText
          class="input"
          [disabled]="disabled"
          [min]="0"
          [(ngModel)]="hours"
          (ngModelChange)="setValueFromInputs()"
          [required]="required"
          type="number"/>
      </div>
    </div>
  </div>
</div>
