import { Injectable } from '@angular/core';
import { Device } from 'app/shared/models';
import { DeviceRequestAccessCreateResponse } from 'app/shared/models/device/device-request-access-create-response.model';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiCenterV2Service } from '../api/api-center-v2.service';
import { ApiCenterService } from '../api/api-center.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { DeviceRequestAccessStatusResponse } from 'app/shared/models/device/device-request-access-status-response.model';
import { WebLoadMap, WebObject, WebResponse } from 'app/center-v2/shared/models';
import { NotAllowedResponse } from 'app/shared/models/not-allowed-response.model';
import { ApiCenterV3Service } from '../api/api-center-v3.service';
import { ApiCenterV3Scope } from '../api/api-center-v3-scope.enum';
import { WebAepClassicLicenseRequestAccess } from 'app/center-v2/shared/models/web-center-types/aep/web-aep-classic-license-request-access.model';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private urlSuffixPlaceholder = 'api/v0/device/{what}';
  private urlSuffixV2Placeholder = 'centerv2/device/{what}';
  private urlSuffixV3Placeholder = 'device/{what}';

  constructor(
    private apiService: ApiCenterService,
    private apiV2Service: ApiCenterV2Service,
    private apiV3Service: ApiCenterV3Service,
  ) { }

  list(siteGuidId: string, solutionProfileGuidId: string): Observable<Device[]> {
    return this.apiService.post<Device[]>(
      this.urlSuffixPlaceholder.replace('{what}', 'list'),
      {
        siteGuidId: siteGuidId,
        solutionProfileGuidId: solutionProfileGuidId
      }
    )
    .pipe(
      map((response: any) => {
        return response && response.devices ? response.devices : null;
      })
    );
  }

  requestAccess(deviceType: 'tv'): Observable<DeviceRequestAccessCreateResponse> {
    return from(FingerprintJS.load())
    .pipe(
      mergeMap((agent: any) => {
        return from(agent.get())
      }),
      mergeMap((result: any) => {
        const fingerprint = result.visitorId;
        return this.apiV2Service.post<any>(
          this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/create'),
          {
            deviceType: deviceType,
            fingerprint: fingerprint,
          }
        );
      }),
    );
  }

  requestAccessStatus(requestAccessGuidId: string): Observable<DeviceRequestAccessStatusResponse> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/status'),
      {
        requestAccessGuidId: requestAccessGuidId,
      }
    );
  }

  requestAccessList(): Observable<WebResponse> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/list'),
      {
      }
    ).pipe(
      map((response: any) => {
        return response ? new WebResponse(response) : undefined;
      })
    );
  }

  requestAccessSetForDevice(requestAccessGuidId: string, deviceGuidId: string, solutionDeviceName: string): Observable<any> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/set'),
      {
        deviceGuidId: deviceGuidId,
        requestAccessGuidId: requestAccessGuidId,
        solutionDeviceName: solutionDeviceName,
      }
    );
  }

  requestAccessSetForDeviceSolutionSet(requestAccessGuidId: string, deviceSolutionSetGuidId: string, deviceSolutionSetTargetSiteGuidId: string, solutionDeviceName: string): Observable<any> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/set'),
      {
        deviceSolutionSetGuidId: deviceSolutionSetGuidId,
        deviceSolutionSetTargetSiteGuidId: deviceSolutionSetTargetSiteGuidId,
        requestAccessGuidId: requestAccessGuidId,
        solutionDeviceName: solutionDeviceName,
      }
    );
  }

  requestAccessSearch(accessCode: string): Observable<WebResponse> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'requestaccess/search'),
      {
        accessCode: accessCode,
      }
    ).pipe(
      map((response: any) => {
        return response?.web2Object ? new WebResponse(response) : undefined;
      })
    );
  }

  requestAccessSearchV3(accessCode: string, web2LoadMap: WebLoadMap): Observable<WebAepClassicLicenseRequestAccess> {
    return this.apiV3Service.post<any>(
      ApiCenterV3Scope.Workspace,
      this.urlSuffixV3Placeholder.replace('{what}', 'requestaccess/search'),
      {
        accessCode: accessCode,
        web2LoadMap: web2LoadMap,
      }
    ).pipe(
      map((response: any) => {
        if (!response?.found) return null;
        if (!response?.web2RequestAccess) return null;

        return new WebAepClassicLicenseRequestAccess(response.web2RequestAccess);
      })
    );
  }

  overviewPauseDeviceLicense(deviceGuidId: string): Observable<NotAllowedResponse> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'overview/devicelicense/pause'),
      {
        deviceGuidId: deviceGuidId,
      }
    );
  }

  overviewUnPauseDeviceLicense(deviceGuidId: string): Observable<NotAllowedResponse> {
    return this.apiV2Service.post<any>(
      this.urlSuffixV2Placeholder.replace('{what}', 'overview/devicelicense/unpause'),
      {
        deviceGuidId: deviceGuidId,
      }
    );
  }

}
