import { Injectable } from '@angular/core';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { ApiCenterV3Scope } from 'app/shared/services/api/api-center-v3-scope.enum';
import { ApiCenterV3Service } from 'app/shared/services/api/api-center-v3.service';
import { BrowserUtils, DownloadUtils } from 'app/shared/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ungzip } from 'pako';


@Injectable({
  providedIn: 'root'
})
export class SolutionDataService {

  static SolutionDataEngineV1GuidId = 'c109e71a-d621-44c6-8ef5-cc1f36562d1e';

  private urlV2SuffixPlaceholder = 'centerv2/solutiondata/{what}';
  private urlV3SuffixPlaceholder = 'reporter/{what}';

  constructor(
    private apiV2Service: ApiCenterV2Service,
    private apiV3Service: ApiCenterV3Service,
  ) { }

  runMap(
    solutionDataMapCallGuidId: string,
    resourceGuidId: string,
    fromSource: boolean,
    sourceRootTypeGuidId?: string,
    sourceGuidId?: string,
    sourceRelationTypeGuidId?: string,
  ): Observable<void> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'map/run'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        resourceGuidId: resourceGuidId,
        fromSource: fromSource,
        sourceRootTypeGuidId: sourceRootTypeGuidId,
        sourceGuidId: sourceGuidId,
        sourceTypeRelationGuidId: sourceRelationTypeGuidId
      }
    ).pipe(
      map((response: any) => {
        return response?.solutionDataMapRunWeb2Object ? response?.solutionDataMapRunWeb2Object : null;
      })
    );
  }

  getModel(
    solutionDataMapCallGuidId: string,
    contentType: string,
    fromSource: boolean,
    sourceRootTypeGuidId?: string,
    sourceGuidId?: string,
    sourceRelationTypeGuidId?: string,
  ): Observable<Blob> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'map/getmodel'),
      {
        solutionDataMapCallGuidId: solutionDataMapCallGuidId,
        contentType: contentType,
        fromSource: fromSource,
        sourceRootTypeGuidId: sourceRootTypeGuidId,
        sourceGuidId: sourceGuidId,
        sourceTypeRelationGuidId: sourceRelationTypeGuidId
      },
      {
        observe: 'body',
        responseType: 'blob',
      }
    );
  }

  reportTemplateGetVersionReportData(reportTemplateVersionGuidId: string): Observable<Blob> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'report/template/getversionreportdata'),
      {
        reportTemplateVersionGuidId: reportTemplateVersionGuidId,
      },
      {
        observe: 'body',
        responseType: 'blob',
      }
    );
  }

  reportTemplateSetVersionReportData(
    reportTemplateVersionGuidId: string,
    resourceBlob: Blob,
  ): Observable<any> {
    let formParams: { append(param: string, value: any): any; };
    formParams = new FormData();

    formParams = formParams.append('siteGuidId', BrowserUtils.getQueryParams().siteGuidId) || formParams;

    if (reportTemplateVersionGuidId !== undefined) {
      formParams = formParams.append('reportTemplateVersionGuidId', reportTemplateVersionGuidId) || formParams;
    }

    if (resourceBlob !== undefined) {
      formParams = formParams.append('contentType', resourceBlob.type) || formParams;
      formParams = formParams.append('content', resourceBlob) || formParams;
    }
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'report/template/setversionreportdata'),
      formParams,
    ).pipe(
      map((response: any) => {
        return response/* ? new WebResponse(response) : null*/;
      })
    );
  }

  reportLoadPreviewData(reportTemplateCID: string): Observable<string> {
    return this.apiV3Service.post(
      ApiCenterV3Scope.Workspace,
      this.urlV3SuffixPlaceholder.replace('{what}', 'preview/load'),
      {
        reportTemplateCID: reportTemplateCID,
      }
    ).pipe(
      map((response: any) => {
        if (response?.previewData?.length < 5) return null;

        const valueUint8Array = ungzip(
          Uint8Array.from(
            window.atob(response?.previewData),
            c => c.charCodeAt(0)
          )
        );
        const decoder = new (window as any).TextDecoder('utf8');
        return JSON.parse(decoder.decode(valueUint8Array));
      })
    );
  }

  reportTestGenerate(reportTemplateCID: string): Observable<string> {
    return this.apiV3Service.post(
      ApiCenterV3Scope.Workspace,
      this.urlV3SuffixPlaceholder.replace('{what}', 'testgenerate'),
      {
        reportTemplateCID: reportTemplateCID,
      }
    ).pipe(
      map((response: any) => {
        return response?.pdfData || response?.PdfData;
      })
    );
  }

}
