import { DictString, SolutionType, SolutionTypeCoreType } from 'app/shared/models';
import { GuidUtils, JsonUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api/selectitem';
import { FilterStringFilter } from '../filter-string/filter-string-filter.model';
import { SolutionDataMapCall } from '../solution-data-type/solution-data-map-call.model';
import { WebObject } from '../web-base';
import { WorkspaceSolutionTypeMember } from './workspace-solution-type-member.model';
import { WebResource } from '../web-center-types/web-resource.model';

interface StyleMap {
  members: WorkspaceSolutionTypeMember[];
  [key: string]: any;
}

export class WorkspaceSolutionType extends SolutionType {

  get isDefault(): boolean {
    return this.baseSolutionTypeGuidId === this.solutionTypeGuidId;
  }
  set isDefault(value: boolean) { return; } // required otherwise the Object.assign on the constructor fails

  workspaceGuidId: string;
  storageGuidId: string;

  associatedWebObjectGuidId?: string;
  canAdd: boolean;
  canDelete: boolean;
  customWorkspaceNavigatorModuleName?: string;
  mainLabelPlaceholder: string;
  mainLabelMember: any;
  noAccess: boolean;

  // specialActions: SelectItem[]; // renamed
  runSystemActions: SelectItem[];
  runSystemReports: SelectItem[];

  filter: {
    filterStringFilter?: FilterStringFilter;
    useBaseDefinition?: boolean;
  };
  translations: any;

  search: {
    customSearchGuidId?: string;
    isSearchable: boolean;
    isDefaultSelected: boolean;
    filterStringFilter?: FilterStringFilter;
  };

  solutionDataMapCallGuidIds: string[];
  $solutionDataMapCalls: SolutionDataMapCall[];

  style: DictString<StyleMap>;

  useWeb2Page: boolean;
  web2PageSize: number;

  widgetShortcuts: {
    widgetGuidId: string;
    icon: string;
    label: string;
    metadata: any;
  }[];

  constructor(item?: Partial<WorkspaceSolutionType>) {
    super(item);

    this.coreType = SolutionTypeCoreType.Tool;
    this.filter = this.filter || { };
    this.search = this.search || { isSearchable: false, isDefaultSelected: true };

    this.style = this.style ? JSON.parse(JSON.stringify(this.style)) : {};
    if (this.style.form) this.style.form.version = this.style.form.version || 2;
    else this.style.form = { members: [], version: 3, readOnly: true };
    if (this.style.grid) this.style.grid.readOnly = this.style.grid.readOnly != null ? this.style.grid.readOnly : true;
    else this.style.grid = this.style.grid || { members: [], readOnly: true };
    if (this.style.new && !this.style.new.addRelationBehavior) this.style.new.addRelationBehavior = ['add']; /** @deprecated */
    if (this.style.new) this.style.new.version = this.style.new.version || 2;
    else this.style.new = { members: [], version: 3 };
    if (!this.style.print) this.style.print = { members: [] };

    this.solutionDataMapCallGuidIds = this.solutionDataMapCallGuidIds || [];

    if (!item) return;

    const anyThis = this as any;
    // OLD STYLE FORMAT:
    for (const key of Object.keys(anyThis.styleMembers || {})) {
      anyThis.style[key] = anyThis.style[key] || { members: [] };
      Object.assign(
        anyThis.style[key],
        {
          members: anyThis.styleMembers[key] || []
        }
      );
    }
    delete anyThis.styleMembers;

    // NEW STYLE FORMAT:
    for (const key of Object.keys(this.style)) {
      if (this.style[key]?.members) {
        if (!Array.isArray(this.style[key].members)) this.style[key].members = [];
        Object.assign(
          this.style[key],
          {
            members: (this.style[key].members || []).map(m => new WorkspaceSolutionTypeMember(m))
          }
        );

        if (this.style[key].members?.length && this.style[key].rowColorMember) {
          this.style[key].rowColorMember = this.style[key].members.find((m: WorkspaceSolutionTypeMember) => {
            return m.guidId === this.style[key].rowColorMember.guidId;
          });
        }
      }
    }

    // regression
    this.canDelete = this.canDelete != null ? this.canDelete : this.style?.form?.canDelete === true;
    this.search.isDefaultSelected = this.search.isDefaultSelected !== false;
    this.style.grid.structureType = this.style.grid.structureType || anyThis.structureType;

    if (anyThis.solutionDataMapCallGuidId) {
      this.solutionDataMapCallGuidIds.push(anyThis.solutionDataMapCallGuidId);
      delete anyThis.solutionDataMapCallGuidId;
    }

    this.solutionTypeGuidId = this.solutionTypeGuidId || this.baseSolutionTypeGuidId;
    this.runSystemActions = this.runSystemActions || anyThis.specialActions;

    // old stuff no longer needed...
    delete anyThis.activeCollectionRelations;
    delete anyThis.activeRelations
    delete anyThis.activeSingleRelations;
    delete anyThis.activeSubTypes;
    delete anyThis.formMembers;
    delete anyThis.gridMembers;
    delete anyThis.specialActions;
    delete anyThis.structureType;
  }

  static new(
    workspaceGuidId: string,
    storageGuidId: string,
    centerTypeGuidId: string,
    name: string,
    isDefault: boolean,
  ) {
    const newItem = new WorkspaceSolutionType();

    newItem.baseSolutionTypeGuidId = centerTypeGuidId;
    newItem.coreType = SolutionTypeCoreType.Tool;
    newItem.name = name;
    newItem.solutionTypeGuidId = isDefault ? newItem.baseSolutionTypeGuidId : GuidUtils.new();
    newItem.storageGuidId = storageGuidId;
    newItem.workspaceGuidId = workspaceGuidId;

    return newItem;
  }

  static find(solutionTypes: WorkspaceSolutionType[], baseSolutionTypeGuidId: string, associatedWebObjectGuidId?: string): WorkspaceSolutionType {
    let result = associatedWebObjectGuidId ? (solutionTypes || []).find(st => st.associatedWebObjectGuidId === associatedWebObjectGuidId && st.baseSolutionTypeGuidId === baseSolutionTypeGuidId) : null;
    result = result || (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === baseSolutionTypeGuidId);
    return result;
  }

  static findAll(solutionTypes: WorkspaceSolutionType[], baseSolutionTypeGuidId: string, associatedWebObjectGuidId?: string): WorkspaceSolutionType[] {
    let result = associatedWebObjectGuidId ? (solutionTypes || []).filter(st => st.associatedWebObjectGuidId === associatedWebObjectGuidId && st.baseSolutionTypeGuidId === baseSolutionTypeGuidId) : [];
    if (!result.length) result = (solutionTypes || []).filter(st => st.baseSolutionTypeGuidId === baseSolutionTypeGuidId);
    return result;
  }


  getMainMemberLabel(webObject: WebObject, userLanguageGuidId?: string): string {
    if (!webObject) return '';
    if (!this.mainLabelPlaceholder) return webObject?.members?.name || webObject?.guidId;

    const mainLabelPlaceholderReplacements = (this.mainLabelPlaceholder.match(/{{([^}]*)}}/g) || [])
    .map((pathPlaceholder: string) => {
      const from = pathPlaceholder;
      const path = from.replace(/{{/g, '').replace(/}}/g, '').trim();
      let to = null;

      if (userLanguageGuidId) {
          const membersPathSubString = path.substring(path.indexOf('members.'));
          const key = membersPathSubString.substring('members.'.length);
          const newFieldPath = path.replace(membersPathSubString, `languageMembers.${key}.${userLanguageGuidId}`);

          to = JsonUtils.deepFind(webObject, newFieldPath || '');
      }

      if (!to) {
        to = JsonUtils.deepFind(webObject, path || '');
        to = to != null ? to : '';
      }
      return {
        from: from,
        to: to,
      };
    }).filter((item: any) => {
      return item;
    });

    let label = this.mainLabelPlaceholder;
    for (const mainLabelPlaceholderReplacement of mainLabelPlaceholderReplacements || []) {
      label = label.replace(mainLabelPlaceholderReplacement.from, mainLabelPlaceholderReplacement.to);
    };

    return label;
  }

}
