<p-button class="lc-button"
  (click)="onClick($event)"
  [disabled]="disabled || asyncDisabled"
  [icon]="icon"
  [iconPos]="iconPos"
  [label]="label"
  [link]="link"
  [outlined]="outlined"
  pRipple
  [rounded]="rounded"
  [severity]="severity"
  [styleClass]="styleClass"
  [text]="text"
  [type]="isFormSubmit ? 'submit' : 'button'">
</p-button>
