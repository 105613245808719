import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';
import { FormFieldModule } from 'app/shared/components/form-field/form-field.module';
import { DragDropModule } from 'primeng/dragdrop';
import { SelectCenterTypeMemberDialogModule } from './dialogs/select-center-type-member/select-center-type-member.module';
import { SelectSolutionTypeMemberDialogModule } from './dialogs/select-solution-type-member/select-solution-type-member.module';
import { FilterStringEditorComponent } from './filter-string-editor.component';
import { PromptDialogModule } from 'app/shared/dialogs/prompt/prompt.module';



@NgModule({
  declarations: [
    FilterStringEditorComponent,
  ],
  exports: [
    FilterStringEditorComponent,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    DragDropModule,
    FormFieldModule,
    PromptDialogModule,
    SelectCenterTypeMemberDialogModule,
    SelectSolutionTypeMemberDialogModule,
  ],
})
export class FilterStringEditorModule { }
