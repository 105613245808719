<p-dialog appendTo="body"
  [styleClass]="'select-site-dialog ' + (isVisible ? 'flex' : '')"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible"
  (onHide)="data = undefined">
  <p-header class="flex-1 flex-row middle">
    <div>
      {{ data?.title || 'Select a Site' }}
    </div>

    <div style="width: 1rem;"></div>

    <lc-button *ngIf="data?.rootObjectGuidId && selectedTreeNode && !data.accessTreeWorkspace"
      [label]="'Search' | translate"
      (click)="searchTree($event)"
      type="secondary">
    </lc-button>
  </p-header>

  <lc-tree *ngIf="data && !data.accessTreeWorkspace"
    #tree
    [rootObjectGuidId]="data?.rootObjectGuidId"
    [displayInTreeTypeGuidIds]="[]"
    [(selectedTreeNode)]="selectedTreeNode">
  </lc-tree>

  <lc-access-site-tree *ngIf="data && data.accessTreeWorkspace"
    #tree
    [selectedSiteGuidId]="data?.selectedSiteGuidId"
    (selectedTreeNodeChange)="selectedTreeNode = $event"
    (selectedTreeNodeClickedAgain)="submit()">
  </lc-access-site-tree>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      [label]="'Cancel' | translate"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [label]="'Ok' | translate"
      (click)="submit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
