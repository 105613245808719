import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { WebObject, WebRelationPointer } from 'app/center-v2/shared/models';



export class WebAepClassicLicenseRequestAccess extends WebObject {

  static typeGuidId = 'cae3f855-ba30-4beb-aaec-d4c98e38712a';

  members: {
    aepClassicApplicationGuidId: string;
    hardwareModel: string;
    hardwareId: string;
    serialnumber: string;
    mAC: string;
    firmware: string;
    aepClassicApplicationKey: string;
    aepClassicApplicationSysVersion: string;
  };

  relations: {
    aepClassicApplication?: WebRelationPointer;
    contractItem?: WebRelationPointer;
    contractItemLicense?: WebRelationPointer;
  };

  constructor(item?: Partial<WebAepClassicLicenseRequestAccess>) {
    super(item);
    this.typeGuidId = this.typeGuidId || WebAepClassicLicenseRequestAccess.typeGuidId;
  }

}