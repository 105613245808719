import { ReportSolutionTypeType } from './report-solution-type.model';


export class ReportSolutionTypeRelation {

  targetType: ReportSolutionTypeType;
  targetGuidId: string;

  $name: string;

  constructor(item?: Partial<ReportSolutionTypeRelation>) {
    Object.assign(this, item);
  }

}
