import { ITooltipParams } from 'ag-grid-community';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/services/app/notification.service';
import QRCode from 'qrcode';


@Component({
  selector: 'lc-grid-tooltip-qrcode',
  templateUrl: 'grid-tooltip-qrcode.component.html',
  styleUrls: ['grid-tooltip-qrcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridTooltipQRcode implements ITooltipAngularComp {

  params: {color: string} & ITooltipParams;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
  ) {

  }

  agInit(params: {color: string} & ITooltipParams): void {
    this.params = params;

    this.generateQRcode(this.params.value);
  }

  refresh(params: any): boolean {
    this.cdr.markForCheck();
    return false;
  }

  private generateQRcode(text: string) {
    setTimeout(() => {
      QRCode.toCanvas(
        this.el.nativeElement.querySelector('#canvas'),
        text,
        {
          height: 128,
          width: 128,
        },
        (error) => {
          if (error) console.error(error)
          // else console.log('success!');
          this.cdr.markForCheck();
        },
      );
      this.cdr.markForCheck();
    }, 10);
  }

}
