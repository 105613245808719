
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { SolutionTypeMember } from './solution-type-member.model';
import { SolutionTypeSpecialValue } from './solution-type-special-value.model';


export enum SolutionTypeCoreType {
  Device = 0,
  Platform = 1,
  Report = 2,
  Set = 3,
  Tool = 4,
}

export class SolutionType {

  guidId: string; // globally unique
  baseSolutionTypeGuidId: string; // usually same as centerTypeGuidId
  solutionTypeGuidId: string; // usually same as centerTypeGuidId but may not be if not default solutionType (or if relation SolutionType)

  coreType: SolutionTypeCoreType;
  solutionProfileGuidId?: string;
  reportTemplateGuidId?: string;
  modifiedDate: string;
  name: string;

  objectTypeGuidIds: string[];

  members: SolutionTypeMember[];

  specialValues: SolutionTypeSpecialValue[];

  compiled: boolean;
  valueTypeString: string;


  constructor(item?: any) {
    Object.assign(this, item);

    this.guidId = this.guidId || GuidUtils.new();

    if (!Array.isArray(this.objectTypeGuidIds)) this.objectTypeGuidIds = [];
    this.objectTypeGuidIds = this.objectTypeGuidIds || [];

    if (!Array.isArray(this.members)) this.members = [];
    this.members = (this.members || []).map((i: SolutionTypeMember) => {
      return new SolutionTypeMember(i);
    });
  }

}
