
export class JsonUtils {

  static deepFind(obj: any, path: string, createMissingNodes?: boolean, createLastNodeAs?: any) {
    if (!path) return obj;

    const paths = path.split('.')
    let current = obj

    for (let i = 0; i < paths.length; ++i) {
      if (paths[i] == null || paths[i] === '') continue;

      if (!current || (!createMissingNodes && current[paths[i]] == null)) {
        return undefined;
      } else {
        if (createMissingNodes && current[paths[i]] == null) {
          current[paths[i]] = i < paths.length -1 ? {} : createLastNodeAs;
        }
        current = current[paths[i]] && typeof current[paths[i]] === 'function' ? current[paths[i]]() : current[paths[i]];
      }
    }
    return current;
  }

}