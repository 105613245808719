import { NgModule } from '@angular/core';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { DropdownModule } from 'primeng/dropdown';
import { FormFieldRelationComponent } from './form-field-relation.component';
import { ButtonComponentModule } from 'app/shared/components/button/button.component.module';


@NgModule({
  declarations: [
    FormFieldRelationComponent,
  ],
  exports: [
    FormFieldRelationComponent,
  ],
  imports: [
    AppSharedV2Module,
    ButtonComponentModule,
    DropdownModule
  ],
})
export class FormFieldRelationModule { }
