import { Injectable } from '@angular/core';
import { WebCenterType, WebLoadMap, WebResponse, Workspace, WorkspacePointer, WorkspaceSolutionType, WorkspaceStructure, WorkspaceType, WorkspaceWidget, WorkspaceWidgetTemplate, WorkspaceWidgetTemplateGroup } from 'app/center-v2/shared/models';
import { DictString, SolutionType, UserSession } from 'app/shared/models';
import { ApiCenterV2Service } from 'app/shared/services/api';
import { DateUtils } from 'app/shared/utils';
import { Observable, of } from 'rxjs';
import { delay, map, mergeMap } from 'rxjs/operators';
import { SolutionDataMapCall } from '../models/solution-data-type/solution-data-map-call.model';
import { GenericService } from './generic.service';
import { WorkspaceWidgetService } from './workspace-widget.service';
import { WorkspaceNavigatorService } from 'app/center-v2/core/workspace-navigator/services/workspace-navigator.service';
import { SessionService, StorageService } from 'app/shared/services/app';
import { ApiCenterV3Service } from 'app/shared/services/api/api-center-v3.service';
import { addSeconds } from 'date-fns';
import { ApiCenterV3Scope } from 'app/shared/services/api/api-center-v3-scope.enum';



@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  private readonly storageCurrentWorkspaceSolutionTypes = 'lc_current_workspace_solution_types';
  private readonly urlV2SuffixPlaceholder = 'centerv2/workspace/{what}';
  private readonly urlV3SuffixPlaceholder = 'workspace/{what}';

  private centerTypesMap: DictString<WorkspaceSolutionType[]>; // cache
  private solutionTypesMap: DictString<WorkspaceSolutionType[]>; // cache

  constructor(
    private apiV2Service: ApiCenterV2Service,
    private apiV3Service: ApiCenterV3Service,
    private genericService: GenericService,
    private sessionService: SessionService,
    private storageService: StorageService,
    private workspaceWidgetService: WorkspaceWidgetService,
  ) {
    this.centerTypesMap = {};
    this.solutionTypesMap = {};
  }

  clearCenterTypesCache(workspaceGuidId: string): void {
    delete this.centerTypesMap[workspaceGuidId]; // clear cache
  }

  listCenterTypes(workspaceGuidId: string): Observable<WebCenterType[]> {
    const request = this.centerTypesMap && this.centerTypesMap[workspaceGuidId] ?
      of({ web2CenterTypes: this.centerTypesMap[workspaceGuidId] })
      .pipe(
        delay(10)
      ) :
      this.apiV2Service.post(
        this.urlV2SuffixPlaceholder.replace('{what}', 'listcentertypes'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    );

    return request.pipe(
      map((response: any) => {
        let result = [];

        for (let item of response?.web2CenterTypes || []) {
          result.push(new WebCenterType(item));
        }

        if (!this.centerTypesMap[workspaceGuidId]) {
          this.centerTypesMap[workspaceGuidId] = result; // set cache
        }

        result.sort((a, b) => {
          return (a.name || '').localeCompare(b.name || '');
        });

        return result;
      })
    );
  }

  clearSolutionTypeCache(workspaceGuidId: string, storageGuidId?: string): void {
    if (storageGuidId) {
      let cacheKey = workspaceGuidId + storageGuidId + 'true';
      delete this.solutionTypesMap[cacheKey]; // clear cache

      cacheKey = workspaceGuidId + storageGuidId + 'false';
      delete this.solutionTypesMap[cacheKey]; // clear cache
    } else {
      let cacheKey = workspaceGuidId;
      delete this.solutionTypesMap[cacheKey]; // clear cache
    }
  }

  /** @deprecated */
  listSolutionTypes(workspaceGuidId: string): Observable<WorkspaceSolutionType[]> {
    const request = this.solutionTypesMap && this.solutionTypesMap[workspaceGuidId] ?
      of({ toolSolutionTypes: this.solutionTypesMap[workspaceGuidId] })
      .pipe(
        delay(10)
      ) :
      this.apiV2Service.post(
        this.urlV2SuffixPlaceholder.replace('{what}', 'toolsolutiontype/list'),
        {
          workspaceGuidId: workspaceGuidId,
        }
      );

    return request.pipe(
        map((response: any) => {
          const result = [];
          for (const st of response?.toolSolutionTypes || []) {
            if (!st) continue;
            // if (st.workspaceGuidId !== workspaceGuidId) continue;

            const wst = new WorkspaceSolutionType(st);
            result.push(wst);
          }

          if (!this.solutionTypesMap[workspaceGuidId]) {
            this.solutionTypesMap[workspaceGuidId] = result; // set cache
          }

          return result;
        })
      );
  }

  /**
   * Used for getting the list of solutionTypes
   * @param workspaceGuidId
   * @param storageGuidId use GuidUtils.emptyGuid() for workspace global solutionTypes or widgetGuidId for widget scoped solutionTypes
   * @param fallbackToWorkspaceSolutionTypes fallback to workspace scoped solutionTypes
   * @param solutionType
   */
  listSolutionTypesV2(workspaceGuidId: string, storageGuidId?: string, fallbackToWorkspaceSolutionTypes?: boolean, solutionTypeGuidIds?: string[]): Observable<WorkspaceSolutionType[]> {
    const cacheKey = workspaceGuidId + (storageGuidId ? (storageGuidId + fallbackToWorkspaceSolutionTypes) : '');

    const request = of(null)
    .pipe(
      mergeMap(() => {
        if (Object.keys(this.solutionTypesMap || {}).length) return of(null);

        return this.storageService.get(this.storageCurrentWorkspaceSolutionTypes)
        .pipe(
          map((jsonObject: any) => {
            if (jsonObject) {
              for (const key of Object.keys(jsonObject)) {
                jsonObject[key] = (jsonObject[key] || []).map(wst => new WorkspaceSolutionType(wst));
              }
              this.solutionTypesMap = jsonObject;
            }

            return null;
          })
        )
      }),
      mergeMap(() => {
        return this.solutionTypesMap && this.solutionTypesMap[cacheKey]
        ? of({ toolSolutionTypes: this.solutionTypesMap[cacheKey] })
        : this.apiV2Service.post(
            this.urlV2SuffixPlaceholder.replace('{what}', 'toolsolutiontype/getstoragecompiled'),
            {
              solutionTypeGuidIds: solutionTypeGuidIds,
              storageGuidId: storageGuidId || undefined,
              workspaceGuidId: workspaceGuidId,
              workspaceSolutionTypes: fallbackToWorkspaceSolutionTypes,
            }
          );
      }),
    )

    return request.pipe(
      map((response: any) => {
        const result = [];
        for (const st of response?.toolSolutionTypes || []) {
          if (!st) continue;
          // if (st.workspaceGuidId !== workspaceGuidId) continue;

          result.push(new WorkspaceSolutionType(st));
        }

        if (!this.solutionTypesMap[cacheKey]) this.solutionTypesMap[cacheKey] = result.slice(0); // set cache

        return result;
      }),
      mergeMap((solutionTypes: WorkspaceSolutionType[]) => {
        if (!fallbackToWorkspaceSolutionTypes) return of(solutionTypes);

        // override base definitions if set...
        const solutionTypeGuidIdsUsingBaseDefinitions = [];
        for (const st of solutionTypes || []) {
          if (
            !st.style?.form?.useBaseDefinition &&
            !st.style?.grid?.useBaseDefinition &&
            !st.style?.new?.useBaseDefinition &&
            !st.style?.print?.useBaseDefinition &&
            !st.filter.useBaseDefinition
          ) continue;

          solutionTypeGuidIdsUsingBaseDefinitions.push(st.baseSolutionTypeGuidId);
        }
        if (!solutionTypeGuidIdsUsingBaseDefinitions.length) return of(solutionTypes);

        return this.listSolutionTypesV2(workspaceGuidId)
        .pipe(
          map((workspaceSolutionTypes: WorkspaceSolutionType[]) => {
            for (const st of solutionTypes || []) {
              for (const styleKey of Object.keys(st.style)) {
                if (!st.style[styleKey]?.useBaseDefinition) continue;

                const correspondingWorkspaceSolutionType = (workspaceSolutionTypes || []).find(wst => wst.isDefault && wst.baseSolutionTypeGuidId === st.baseSolutionTypeGuidId);
                if (correspondingWorkspaceSolutionType) Object.assign(st.style[styleKey], correspondingWorkspaceSolutionType.style[styleKey]);
              }

              if (st.filter.useBaseDefinition) {
                const correspondingWorkspaceSolutionType = (workspaceSolutionTypes || []).find(wst => wst.isDefault && wst.baseSolutionTypeGuidId === st.baseSolutionTypeGuidId);
                if (correspondingWorkspaceSolutionType) Object.assign(st.filter, correspondingWorkspaceSolutionType.filter);
              }
            }
            return solutionTypes;
          })
        );
      }),
      mergeMap((solutionTypes: WorkspaceSolutionType[]) => {
        let solutionDataMapCallGuidIds = [];
        for (const st of solutionTypes) {
          st.$solutionDataMapCalls = [];
          solutionDataMapCallGuidIds.push(...st.solutionDataMapCallGuidIds);
        }
        solutionDataMapCallGuidIds = solutionDataMapCallGuidIds.filter(x => x);

        if (!solutionDataMapCallGuidIds?.length) return of(solutionTypes);

        return this.genericService.getBatch(solutionDataMapCallGuidIds, new WebLoadMap(), false, workspaceGuidId)
        .pipe(
          mergeMap((response: WebResponse) => {
            const solutionDataMapCalls = response.getWebObjects() as SolutionDataMapCall[];
            for (const solutionDataMapCall of solutionDataMapCalls || []) {
              const st = solutionTypes.find(x => x.solutionDataMapCallGuidIds.indexOf(solutionDataMapCall.guidId) >= 0);
              if (!st) continue;

              st.$solutionDataMapCalls.push(solutionDataMapCall);
            }

            return this.storageService.set(this.storageCurrentWorkspaceSolutionTypes, this.solutionTypesMap);
          }),
          map(() => {
            return solutionTypes;
          })
        );
      }),
    );
  }

  /**
   * Used for new / update / delete
   * @param workspaceGuidId
   * @param storageGuidId use GuidUtils.emptyGuid() for workspace global solutionTypes or widgetGuidId for widget scoped solutionTypes
   * @param solutionTypeGuidId
   * @param solutionType
   */
  updateSolutionType(workspaceGuidId: string, storageGuidId: string, solutionTypeGuidId: string, solutionType: SolutionType): Observable<any> {
    if (solutionType) solutionType.modifiedDate = DateUtils.nowAsISOString();

    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'toolsolutiontype/update'),
      {
        workspaceGuidId: workspaceGuidId,
        storageGuidId: storageGuidId || undefined,
        toolSolutionTypeGuidId: solutionTypeGuidId,
        toolSolutionType: solutionType,
      }
    ).pipe(
      mergeMap((response: any) => {
        return this.storageService.set(this.storageCurrentWorkspaceSolutionTypes, this.solutionTypesMap)
        .pipe(
          map(() => { return response; })
        );
      }),
      map((response: any) => {
        this.clearSolutionTypeCache(workspaceGuidId, storageGuidId);
        return response;
      })
    );
  }

  adminNewUserWorkspace(userGuidId: string, parentWorkspaceGuidId: string, name: string): Observable<void> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'admin/newuserworkspace'),
      {
        parentWorkspaceGuidId: parentWorkspaceGuidId,
        userGuidId: userGuidId,
        name: name,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspace : [];
      })
    );
  }

  setUserWorkspace(userGuidId: string, workspaceGuidId: string, sortOrder?: number, favourite?: boolean, favouriteGroup?: string, hide?: boolean, remove?: boolean): Observable<void> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'userworkspace'),
      {
        workspaceGuidId: workspaceGuidId,
        userGuidId: userGuidId,
        sortOrder: sortOrder,
        favourite: favourite,
        favouriteGroup: favouriteGroup,
        hide: hide,
        remove: remove,
      }
    );
  }

  getUserWorkspaces(solutionProfileGuidId?: string): Observable<WorkspacePointer[]> {
    return this.apiV2Service.post<WorkspacePointer[]>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'getuserworkspaces'),
      {
        solutionProfileGuidId: solutionProfileGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspacePointers : [];
      })
    );
  }

  newWorkspace(
    siteGuidId: string,
    solutionProfileGuidId: string,
    parentWorkspaceGuidId: string,
    name: string,
    demo: boolean,
    shared: boolean,
    color: string,
    workspaceType: WorkspaceType,
    primaryEnvironmentGuidId: string,
  ): Observable<Workspace> {
    return this.apiV2Service.post<Workspace>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'new'),
      {
        siteGuidId: siteGuidId,
        solutionProfileGuidId: solutionProfileGuidId,
        parentWorkspaceGuidId: parentWorkspaceGuidId,
        name: name,
        demo: demo,
        shared: shared,
        color: color,
        workspaceType: workspaceType,
        primaryEnvironmentGuidId: primaryEnvironmentGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  updateWorkspace(
    workspaceGuidId: string,
    name: string,
    demo: boolean,
    shared: boolean,
    color: string,
  ): Observable<Workspace> {
    return this.apiV2Service.post<Workspace>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'update'),
      {
        workspaceGuidId: workspaceGuidId,
        name: name,
        demo: demo,
        shared: shared,
        color: color,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  getDefaultWorkspaceSiteGuidId(workspaceGuidId?: string): Observable<string> {
    return this.apiV2Service.post<WorkspacePointer[]>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'defaultworkspacesite'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.siteGuidId : null;
      })
    );
  }

  getStructure(workspaceGuidId?: string): Observable<WorkspaceStructure> {
    return this.apiV2Service.post<WorkspacePointer[]>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'getstructure'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.workspaceStructure : null;
      })
    );
  }

  /*getWorkspace(workspaceGuidId: string): Observable<Workspace> {
    return this.apiService.post<Workspace>(
      this.urlSuffixPlaceholder.replace('{what}', 'get'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        const workspace = response ? new Workspace(response.workspace) : null;
        this.workspaceWidgetService.setWorkspace(workspace);
        return workspace
      })
    );
  }*/

  getCompiledWorkspace(workspaceGuidId: string, changeTick?: number): Observable<Workspace> {
    return this.apiV2Service.post<Workspace>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'getcompiled2'),
      {
        changeTick: changeTick,
        loadTemplate: changeTick ? 'Base' : 'Complete',
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        const compiledWorkspace = response ? new Workspace(response.compiledWorkspace) : null;
        let workspace = this.workspaceWidgetService.getWorkspace();
        if (workspace && compiledWorkspace) {
          // if we already have a "Complete" workspace, we don't want to clear these when it gets changed
          compiledWorkspace.languageJson = compiledWorkspace.languageJson || workspace.languageJson;
          compiledWorkspace.solutionProfile = compiledWorkspace.solutionProfile || workspace.solutionProfile;
          compiledWorkspace.web2Environments = compiledWorkspace.web2Environments || workspace.web2Environments;
          compiledWorkspace.web2Languages = compiledWorkspace.web2Languages || workspace.web2Languages;
          compiledWorkspace.web2PrimaryEnvironment = compiledWorkspace.web2PrimaryEnvironment || workspace.web2PrimaryEnvironment;

          Object.assign(workspace, compiledWorkspace);
        } else {
          workspace = compiledWorkspace;
        }
        this.workspaceWidgetService.setWorkspace(workspace);
        return workspace
      }),
      mergeMap((workspace: Workspace) => {
        return this.getWorkspaceToken(workspace);
      })
    );
  }

  private getWorkspaceToken(workspace: Workspace): Observable<Workspace> {
    if (!workspace.core3) {
      return this.sessionService.get()
      .pipe(
        map((currentSession: UserSession) => {
          currentSession.workspaceCID = workspace.cid;
          currentSession.workspaceAccessToken = undefined;
          currentSession.workspaceAccessTokenExpiredDate = undefined;
          currentSession.workspaceRefreshToken = undefined;
          currentSession.workspaceRefreshTokenExpiredDate = undefined;
          return this.sessionService.set(currentSession);
        }),
        map(() => {
          return workspace;
        })
      );
    }

    return this.apiV3Service.post(
      ApiCenterV3Scope.User,
      this.urlV3SuffixPlaceholder.replace('{what}', 'token/get'),
      {
        workspaceCID: workspace.cid,
      }
    ).pipe(
      mergeMap((sessionV3: any) => {
        return this.sessionService.get()
        .pipe(
          mergeMap((currentSession: UserSession) => {
            let now = new Date();
            currentSession.workspaceCID = workspace.cid;
            currentSession.workspaceAccessToken = sessionV3.access_token;
            currentSession.workspaceAccessTokenExpiredDate = addSeconds(now, sessionV3.expires_in).toISOString();
            currentSession.workspaceRefreshToken = sessionV3.refresh_token;
            currentSession.workspaceRefreshTokenExpiredDate = addSeconds(now, sessionV3.refresh_token_expires_in).toISOString();
            return this.sessionService.set(currentSession);
          }),
          map(() => {
            return workspace;
          })
        );
      })
    );
  }

  verifyCompiledWorkspace(workspace: Workspace): Observable<{ invalidCompiled: boolean }> {
    return this.apiV2Service.post<any>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'verifycompiledworkspace'),
      {
        changeTick: workspace.changeTick,
        workspaceGuidId: workspace.guidId,
      }
    ).pipe(
      mergeMap((response: { invalidCompiled: boolean }) => {
        if (response.invalidCompiled) return of(response);

        return this.sessionService.get()
        .pipe(
          mergeMap((currentSession: UserSession) => {
            if (!currentSession) return of(response);

            if (
              currentSession.workspaceCID !== workspace.cid
              || !currentSession.workspaceAccessToken
              || new Date(currentSession.workspaceAccessTokenExpiredDate) < new Date()
            ) {
              return this.getWorkspaceToken(workspace);
            } else {
              return this.sessionService.set(currentSession);
            }
          }),
          map(() => {
            return response;
          })
        );
      })
    );
  }

  addWidget(
    workspaceGuidId: string,
    widgetTemplateGuidId: string,
    workspaceWidgetSettingJson: string,
  ): Observable<WorkspaceWidget> {
    return this.apiV2Service.post<WorkspaceWidget>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/add'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetTemplateGuidId: widgetTemplateGuidId,
        workspaceWidgetSettingJson: workspaceWidgetSettingJson,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  updateWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
    workspaceWidgetSettingJson: string,
  ): Observable<WorkspaceWidget> {
    return this.apiV2Service.post<WorkspaceWidget>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/update'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
        workspaceWidgetSettingJson: workspaceWidgetSettingJson,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  removeWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
  ): Observable<Workspace> {
    return this.apiV2Service.post<Workspace>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/remove'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new Workspace(response.workspace) : null;
      })
    );
  }

  setSettingWidget(
    workspaceGuidId: string,
    widgetGuidId: string,
    settingGuidId: string,
    removeSetting: boolean,
    name: string,
    sysVersion: number,
    jsonValue: any,
  ): Observable<WorkspaceWidget> {
    return this.apiV2Service.post<WorkspaceWidget>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/setsetting'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetGuidId: widgetGuidId,
        settingGuidId: settingGuidId,
        removeSetting: removeSetting,
        name: name,
        sysVersion: sysVersion,
        jsonValue: jsonValue || 'null',
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      })
    );
  }

  getWidget(widgetGuidId: string): Observable<WorkspaceWidget> {
    return this.apiV2Service.post<WorkspaceWidget>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/get'),
      {
        widgetGuidId: widgetGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidget(response.widget) : null;
      }),
      // share(),
      // take(1),
    );
  }

  getWidgetTemplate(widgetTemplateGuidId: string): Observable<WorkspaceWidgetTemplate> {
    return this.apiV2Service.post<WorkspaceWidget>(
      'centerv2/coreadmin/{what}'.replace('{what}', 'widget/template/get'),
      {
        widgetTemplateGuidId: widgetTemplateGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? new WorkspaceWidgetTemplate(response.widgetTemplate) : null;
      })
    );
  }

  listGroupAccess(workspaceGuidId: string): Observable<WorkspaceWidgetTemplateGroup[]> {
    return this.apiV2Service.post<WorkspaceWidgetTemplateGroup[]>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/template/listgroupaccess'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    ).pipe(
      map((response: any) => {
        return response ? response.widgetTemplateGroups : [];
      })
    );
  }

  listTemplateAccess(workspaceGuidId: string, widgetTemplateGroupGuidId: string): Observable<WorkspaceWidgetTemplate[]> {
    return this.apiV2Service.post<WorkspaceWidgetTemplate[]>(
      this.urlV2SuffixPlaceholder.replace('{what}', 'widget/template/listtemplateaccess'),
      {
        workspaceGuidId: workspaceGuidId,
        widgetTemplateGroupGuidId: widgetTemplateGroupGuidId,
      }
    ).pipe(
      map((response: any) => {
        const result = [];
        if (response) {
          for (const wt of response.widgetTemplates || []) {
            result.push(new WorkspaceWidgetTemplate(wt));
          }
        }
        return result;
      })
    );
  }

  clearCache(workspaceGuidId: string): Observable<void> {
    return this.apiV2Service.post(
      this.urlV2SuffixPlaceholder.replace('{what}', 'clearcache'),
      {
        workspaceGuidId: workspaceGuidId,
      }
    );
  }

}
