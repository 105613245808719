import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { GridUtils } from 'app/shared/utils';

@Component({
  selector: 'lc-grid-celleditor-center-type-tree',
  templateUrl: 'grid-celleditor-center-type-tree.component.html',
  styleUrls: ['grid-celleditor-center-type-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorCenterTypeTree implements ICellEditorAngularComp, AfterViewInit {

  params: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
  }

  ngAfterViewInit() {

  }

  agInit(params): void {
    this.params = params;

    this.params.webCenterTypes = GridUtils.getParamValue(this.params, this.params.webCenterTypes);
    this.params.selectedST = GridUtils.getParamValue(this.params, this.params.selectedST);
    this.params.solutionTypes = GridUtils.getParamValue(this.params, this.params.solutionTypes);

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams?.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition(): 'over' | 'under' | undefined {
    return 'over';
  }

  selectedMemberChanged() {
    this.params.value = this.params.selectedST.$fieldOptionsRelationMember;

    delete this.params.selectedST.$fieldOptionsRelationMember;
    this.cdr.markForCheck();
  }

}
