<div class="flex-col gap-1 cell-wrapper">
  <lc-center-type-tree
    [centerTypes]="params.webCenterTypes"
    [groups]="['RelationsWithMembers', 'Relations', 'SubTypes']"
    [limitToXLevels]="4"
    membersKey="$fieldOptionsRelationMember"
    (selectedMembersChanged)="selectedMemberChanged()"
    [singleSelection]="true"
    singleSelectionType="relation"
    [solutionType]="params.selectedST"
    [solutionTypes]="params.solutionTypes">
  </lc-center-type-tree>
</div>
